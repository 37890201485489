/* ==========================================================================
   Bases
   ========================================================================== */
.page {
	min-height: calc(100vh - 115px);
}

.logo-text,
.logo {
	font-family: 'Ruda', sans-serif;
}

.logo_login {
	max-width: 200px;
}

.logo-icon img {
	margin-top: -5px;
}

.name_top {
    text-transform: uppercase;
    font-weight: 500;
    padding: 22px 20px!important;
}

.body-auth {
	.content-container,
	.page-signin,
	.page-forgot {
		margin: 0!important;
	}
}

.app > .main-container > .content-container {
	overflow: visible;
}

.header-container > .top-header .menu-button {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

	.icon-bar {
	    -webkit-transition: all 0.2s ease-out;
	    transition: all 0.2s ease-out;
	}

	&:hover {
	    background-color: #EFEFEF;

		.icon-bar {
		    background-color: #262B33;
		}
	}
}

.breadcamb {
	a {
		color: #3D4051;
		text-decoration: none;
	}
	a:hover,
	a:focus {
		color: #3D4051;
		text-decoration: underline;
	}
	i {
	    font-size: 10px;
	    color: #bbb;
	    margin: 0 2px;
	}
}

.row {
	margin-left: -7.5px;
	margin-right: -7.5px;

	> div[class*="col-"] {
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
}

.form-horizontal label {
	font-weight: normal;
}

.form-control {
	height: 36px;
	padding: 7px 12px!important;

	&:focus {
		background-color: #F2F7F7;
		border-color: #E2E8EC;
	}

	&.disabled,
	&.disabled:focus {
		background-color: #eee;
	}
}

.input-sm {
	height: 30px!important;
	padding: 5px!important;
}

.has-error {
	.form-control,
	.form-control:focus,
	button.btn.dropdown-toggle.btn-white {
	    border-color: #E8B5B1;
	    box-shadow: none;
	    background: #FFF8F8;
	}

	span.error {
	    margin: 2px 0 0;
	    color: #EC574B;
	    font-size: 11px;
	}
}

// Custom Select
.bootstrap-select {
	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		width: 100%;
	}

	.dropdown-menu {
		max-width: 100%;
		z-index: 9999;
	}

	.dropdown-toggle:focus {
	    outline: none!important;
	}

	&.btn-group .no-results {
		padding: 10px!important;
	    margin: 0!important;
	}
}

.select_vue {
	position: relative;

	.btn_clear_result {
		background: #fff;
		color: #ccc;
		width: 20px;
		height: 20px;
		text-align: center;
		border-radius: 100%;
		position: absolute;
		right: 5px;
		top: 8px;
		z-index: 99;
		font-size: 10px;
		line-height: 22px;
		cursor: pointer;
	    -webkit-transition: all 0.2s ease-out;
	    transition: all 0.2s ease-out;

	    &:hover {
			color: #333;
		}
	}
}

.bs-searchbox, 
.bs-actionsbox, 
.bs-donebutton {
	padding: 6px!important;
}

button.btn.dropdown-toggle.btn-white {
    background: #fff;
    border: solid 1px #CBD5DD;
    font-weight: normal;
    padding-right: 24px!important;

    span {
		white-space: nowrap;
		text-overflow: ellipsis;
    }

    &:focus {
		background-color: #F2F7F7;
		border-color: #E2E8EC;
    }
}

.bootstrap-select .dropdown-toggle .filter-option-inner {
	padding-right: 0;
}

.btn-group.open .dropdown-toggle {
	box-shadow: none;
}

.autocomplete-suggestions {
    min-width: 160px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    overflow: auto;

    .autocomplete-suggestion {
	    display: block;
	    padding: 10px 15px;
	    clear: both;
	    font-weight: normal;
	    line-height: 1.428571429;
	    color: #333333;
	    white-space: normal;
	    -webkit-hyphens: auto;
	    -moz-hyphens: auto;
	    hyphens: auto;
	    -epub-hyphens: auto;
	    -ms-word-wrap: break-word;
	    -webkit-font-smoothing: antialiased;
	    cursor: pointer;
	    -webkit-transition: all 0.2s ease-out;
	    transition: all 0.2s ease-out; 
	}
}

.autocomplete-suggestion:hover,
.autocomplete-selected,
.bootstrap-select.btn-group .dropdown-menu li.selected a {
	text-decoration: none;
    color: #fff!important;
    background-color: #009688;
}

.help-block {
    color: #F44336;
    float: left;
    width: 100%;
    font-size: 12px;
}

/* ==========================================================================
   Custom Drropzone
   ========================================================================== */
.dropzone {
    min-height: auto !important;
    border: transparent;
    border-radius: 2px !important;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1) !important;
    background: #F2F7F7 !important;
    margin: 0!important;
    padding: 20px !important;

    .dz-default {
		margin: 0!important;
		cursor: pointer;
		padding-top: 35px;
		position: relative;

		&:after {
			content: "\f093";
			position: absolute;
			left: calc(50% - 12.5px);
			top: 5px;
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 25px;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
    }
}

/* ==========================================================================
   Custom Buttons
   ========================================================================== */
.btn {
	padding: 7px 12px!important;
	font-weight: 500;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	&:focus,
	&.focus,
	&:active:focus,
	&:active.focus,
	&.active:focus,
	&.active.focus {
	    outline: none!important;
	    box-shadow: none!important;
	}
}

.btn-sm {
	padding: 5px 10px!important;
}

.btn-xs {
	width: 25px;
	height: 25px;
	padding: 0!important;
	i {
		line-height: 25px;
	}
}

/* ==========================================================================
   Color Placeholder
   ========================================================================== */
::-webkit-input-placeholder {
	color: #777;
    font-weight: 500!important;
}

:-moz-placeholder {
	color: #777;
    font-weight: 500!important;
}

::-moz-placeholder {
	color: #777;
    font-weight: 500!important;
}

:-ms-input-placeholder {
	color: #777;
    font-weight: 500!important;
}

/* Custom tooltip */
.tooltip-inner {
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 500;
    background-color: #262B33;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #262B33;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #262B33;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #262B33;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #262B33;
}

/* Custons */
.nav-container .nav > li > a {
	white-space: normal;
}

.input_icon {
    position: relative;
    padding-right: 50px;

	button {
	    position: absolute;
	    right: 0;
	    top: calc(50% - 15px);
	}
}

.input_autocomplete input {
	padding: 0;
    border: 0;
    border-radius: 0;
    margin: 18px 0;
    border-bottom: solid 1px #E1E1E1;
	&:focus {
	    border-bottom: solid 2px #009688;
	    box-shadow: none;
	}
}

.form_add_cont md-select {
	margin: 0;
}

.page_number {
	width: 70px;
	padding: 0!important;
	margin: 0!important;

	> select {
		padding: 8px 15px 8px 10px!important;
	}
}

.nav-collapsed-min .balance_menu {
	display: none;
}

.page {
	padding: 15px;
}

.pagination {
	margin: 0;
}

.title_page {
    margin-bottom: 5px;
    width: 100%;
    display: inline-block;
}

.name_profile {
	line-height: 36px;
	text-transform: center;
}

/*==========================================================================
	Custom Login
===========================================================================*/
.body-auth {
	background-image: url(../images/background/bg.png);
}

.transparent_fillet {
	height: 100vh;
	background-color: rgba(255,255,255,0.05);
	padding: 0 60px;

	.has-error span.error {
	    margin: 4px 0 0;
	    color: #EC574B;
	    font-size: 9px!important;
	    font-weight: 600;
	    display: block;
	    text-transform: uppercase;
	    letter-spacing: 0.5px;
	}
}

.custom-wrapper {
	margin: 0;
}

.main-body-custom {
	max-width: 480px;
    margin: 0 auto;
}

.custom-body-inner {
	position: relative;
}

.custom_input {
    border-radius: 0!important;
    background-color: #e6e4e4;
    text-align: center;
    color: #333;
    height: 50px;
    width: 100%;
    border: 0;

    &:focus {
    	background-color: #fff;
    }
}

.btn_login,
.btn_login:hover,
.btn_login:focus {
    background-color: rgba(78, 146, 146, 1)!important;
    border: solid 1px #4e9292!important;
    border-radius: 0!important;
    padding: 15px 0!important;
    color: #fff;
    display: block;
    width: 100%;
}

.label_input {
	display: block;
	width: 100%;
	margin: 0 0 15px;

	> span {
		font-size: 12px;
	    display: block;
	    margin-bottom: 3px;
	    font-weight: 500;
	    color: #626571;
	}
}

.over_auto {
	overflow: auto;
}

.over_hide {
	overflow: hidden
}

.info_help {
	position: relative;
	padding-right: 40px;

	> button {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 10px;
		top: calc(50% - 12px);
		background-color: #E5E5E5;
		border: none;
		color: #4C4F5F;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;

		&:hover,
		&:focus {
			color: #fff;
			background-color: #3D4051;
		}
	}
}

/* 	==========================================================================
	Custom Notify
	==========================================================================	*/
div[class*="notifyjs-metro-"] {
	display: table;

	.image,
	.text-wrapper {
		position: relative;
		left: auto;
		top: auto;
		display: table-cell;
		vertical-align: middle;
		padding: 10px;
		transform: none;
		-webkit-transform: none;
	}

	.text-wrapper {
		max-width: 290px;
		padding-left: 0;
	}
}

div[class*="notifyjs-metro-"],
.notifyjs-metro-base .text-wrapper {
	color: #fff;
	font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
	border-radius: 3px;
}

.notifyjs-metro-base {

	.image i {
		font-size: 28px;
	}

	.text {
		font-weight: 600;
	}
}

.notifyjs-metro-success {
    background-color: #009688!important;
    border: 1px solid #009688!important;
}

.notifyjs-metro-warning {
    background-color: #FFCA28!important;
    border: 1px solid #FFCA28!important;
}

.notifyjs-metro-info {
    background-color: #00BCD4!important;
    border: 1px solid #00BCD4!important;
}

.notifyjs-metro-custom {
    background-color: #586980!important;
    border: 1px solid #586980!important;
}

/* ==========================================================================
   Block Boxes
   ========================================================================== */
.box_disabled {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255,255,255,0.8);
    z-index: 99;
    text-align: center;

	i {
	    font-size: 30px;
	    color: #F79638;
	}

	span {
		display: block;
		margin-top: 10px;
	    color: #3D4051;
	    font-size: 15px;
	    font-weight: 500;
	}
}

/* ==========================================================================
   Custom Modals
   ========================================================================== */
.modal-body {
    position: relative;
    padding: 0;
}

.modal_content {
    border-bottom: solid 1px #E5E5E5;
    padding: 20px!important;
}

.modal_alert {
	text-align: center;

	md-dialog-actions {
	    width: 100%;
	    display: block;
	    min-height: 1px;
	    padding: 0 15px 15px;

		button {
		    color: #fff!important;
		    margin: 0;
		    padding: 6px 8px;
		    font-size: 12px;
		    min-height: 1px;

			&:first-child,
			&:first-child:hover,
			&:first-child:focus {
			    float: left!important;
			    background-color: #F44336!important;
			    border-color: #F44336!important;
			}

			&:last-child,
			&:last-child:hover,
			&:last-child:focus {
				float: right!important;
			    background-color: #009688!important;
			    border-color: #009688!important;
			}
		}
	}
}

/* ==========================================================================
   Custom Dropdowns
   ========================================================================== */
.panel_dropdown {
	position: relative;

	.panel-heading {
		text-transform: uppercase;
	    font-weight: 500;
	    font-size: 12px;
	    background: #F2F7F7;
	}
}

.dropdown-menu {
	padding: 0;
	border: none;
	margin: 0;

	> li > a {
		padding: 10px 15px;
		white-space: normal;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
		-epub-hyphens: auto;
		-ms-word-wrap: break-word;
		-webkit-font-smoothing: antialiased;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		font-size: 12px;

		i {
			font-size: 18px;
			width: 18px;
			margin: 2px 5px 0 0;
		}

		&:hover i,
		&:hover span {
			color: #fff;
		}
	}

	&:after {
		display: none;
	}
}

/* ==========================================================================
   Custom Tables
   ========================================================================== */
.table_full {
	overflow: auto;
	position: relative;
}

.table-dynamic .table-filters,
.table-dynamic .table-footer {
	margin: 0;
    z-index: 500;
}

.table-filters {
	position: absolute;
	top: 0;
	right: 15px;
	padding: 0!important;
}

.table>thead>tr>th:first-child,
.table>thead>tr>td:first-child,
.table>tbody>tr>th:first-child,
.table>tbody>tr>td:first-child,
.table>tfoot>tr>th:first-child,
.table>tfoot>tr>td:first-child {
	border-left: none
}

.table>thead>tr>th:last-child,
.table>thead>tr>td:last-child,
.table>tbody>tr>th:last-child,
.table>tbody>tr>td:last-child,
.table>tfoot>tr>th:last-child,
.table>tfoot>tr>td:last-child {
	border-right: none
}

table.list {
    border: none;
    margin: 0!important;
    border-top: solid 1px #ddd;

	thead tr th,
	tbody tr td {
		vertical-align: middle!important;
		padding: 6px 8px;
		font-size: 13px;

		.btn-icon {
			display: inline-table;
		}
	}
}

.table_cash_flow {
	thead tr th {
	    background: #F2F7F7;
	    text-align: center;
	    text-transform: uppercase;
	}
	thead tr th,
	thead tr td,
	tbody tr th,
	tbody tr td {
		padding: 10px;
	    font-size: 13px;
	}
}

.table-hover > tbody > tr {
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.th_sort {
	position: relative;
	cursor: pointer;
	padding-right: 30px!important;

	&:after {
	    position: absolute;
	    top: calc(50% - 4px);
	    right: 8px;
	    display: block;
	    font-family: 'Glyphicons Halflings';
	    opacity: 0.2;
	    content: "\e150";
	}

	&.asc:after {
	    opacity: 0.4;
		content: "\e155";
	}

	&.desc:after {
	    opacity: 0.4;
		content: "\e156";
	}
}

.table_extract {
	thead th {
		padding: 8px!important;

		&.th_sort {
			padding-right: 30px!important;
		}
	}

	tbody td {
		cursor: pointer;
	}
}

table.table_ofx {
    min-width: 100%;

	thead, 
	tbody, 
	td, 
	th { 
		display: block; 
	}

	tr {
		display: flex;
	
		&:after {
		    content: ' ';
		    display: block;
		    visibility: hidden;
		    clear: both;
		}
	}

	thead th {
	    height: 32px;
	    text-align: center;
	}

	thead tr th,
	tbody tr td {
	    float: left;
		flex: 1 1 auto;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: center;

	    &:nth-child(1) {
	    	min-width: 40px;
	    	width: 2%;
	    	max-width: 40px;
	    }

	    &:nth-child(2) {
	    	min-width: 100px;
	    	width: 6%;
	    	max-width: 100px;
	    }

	    &:nth-child(3),
	    &:nth-child(5),
	    &:nth-child(7) {
	    	min-width: 180px;
	    	width: 15%;
	    }

	    &:nth-child(4),
	    &:nth-child(6) {
	    	min-width: 180px;
	    	width: 20%;
	    }

	    &:nth-child(8) {
	    	min-width: 120px;
	    	width: 8%;
	    	max-width: 120px;
	    }
	}

	tbody {
	    max-height: calc(100vh - 120px);
	    overflow-y: auto;

	    td {
	    	min-height: 79px;

		    &.null_item {
		    	min-width: 100%!important;
		    	min-height: 32px;
		    	text-align: center;
		    }

		    > * {
		    	width: 100%;
		    }
	    }

	    * {
	    	font-size: 12px!important;
	    }
	}

	.attention {
		display: block;
	    margin: 0 0 10px;
	    color: #ec564b;
	    font-size: 16px!important;
	}
}

.box_ofx_info {
	background: #f2f7f7;
	padding-top: 20px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 20px;
	margin-top: 20px;

	> div {
	    border-right: solid 1px #ddd;

	    &:last-child {
	    	border: none;
	    }
	}
}

/* ==========================================================================
   Custom Autocomplete
   ========================================================================== */
.angucomplete-dropdown {
    width: 100%;
    z-index: 1000;
    margin-top: 30px;
    padding: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}

.angucomplete-searching {
    padding: 8px 15px;
}

.angucomplete-holder input {
    height: 38px;
    display: block;
    margin-top: 0;
    background: 0 0;
	padding: 8px 12px !important;
    border-width: 0 0 1px;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left;
	&:focus {
		box-shadow: inset 0 -1px 0 #009688;
	}
}

.angucomplete-row {
	margin: 0;
	padding: 8px 15px;
	color: #3D4051;
}

.angucomplete-selected-row {
	background-color: #009688;
	padding: 8px 15px;
	color: #fff;
}

.dropdown-header {
    padding: 5px 10px;
    white-space: normal;
}

/* ==========================================================================
   Plan account
   ========================================================================== */
.tree {
  	list-style: none;
  	padding-left: 10px;

	ol {
	  margin: 0;
	  padding: 0;
	  list-style: none;
	  color: #333;
	  position: relative;
	}

	&:before,
	ol:before {
	    content: "";
	    display: block;
	    width: 0;
	    position: absolute;
	    top: -16px;
	    bottom: 0;
	    left: 0;
	    border-left: 1px solid;
	}

	li {
		position: relative;
		padding-left: 20px;

		&:before {
			content: "";
			display: block;
			width: 15px;
			height: 0;
			border-top: 1px solid;
			position: absolute;
			top: 24px;
			left: 0;
		}

		&:last-child:before {
		  background: white;
		  height: auto;
		  bottom: 0;
		}
	}
}

.box_list_plan > .tree > li {
	padding-left: 12px!important;

	> button {
		left: 0!important;
	}
}

.list_plan_cont {
    position: relative;
    height: 36px;
    line-height: 36px;
    color: #777;
    font-weight: 400;
    margin: 0;
    padding: 5px 70px 5px 0;
    cursor: default;
    border-radius: 0;
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;

	> span {
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    overflow: hidden;
	    display: block;
	}

	.text_plan {
		padding-left: 20px;
	    color: #333;
	    font-weight: 500;
	    text-transform: uppercase;
	}

	> div {
		opacity: 0.2;
	    position: absolute;
	    z-index: 99;
	    right: 10px;
	    top: 9px;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;

		.btn-xs {
			cursor: pointer;
		}
	}

	&:hover > div {
		opacity: 1.0;
	}
}

.btn_plus_plan {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    top: 13px;
    background-color: #E5E5E5;
    z-index: 9;
    border: none;
    color: #4C4F5F;
    border-radius: 100%;
    font-size: 12px;
    line-height: 22px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:hover {
		color: #fff;
		text-decoration: none;
		background-color: #475568;
		border-color: #3f4b5c;
    }
}

.or {
	display: inline-block;
	position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 10px);
    font-weight: bold;
    width: 30px;
    height: 30px;
    background: #9C9DA6;
    line-height: 30px;
    color: #fff;
    border-radius: 100%;
    font-size: 11px;

    &:after,
    &:before {
		content: "";
		display: block;
		position: absolute;
		background: #E5E5E5;
		width: 2px;
		height: 70px;
		left: calc(50% - 1px);
		z-index: 1;
    }

    &:after {
		bottom: 100%;
		background: -webkit-linear-gradient(bottom, #E5E5E5, #fff);
		background: -o-linear-gradient(bottom, #E5E5E5, #fff);
		background: -moz-linear-gradient(bottom, #E5E5E5, #fff);
		background: linear-gradient(bottom, #E5E5E5, #fff);
	}

    &:before {
    	top: 100%;
		background: -webkit-linear-gradient(top, #E5E5E5, #fff);
		background: -o-linear-gradient(top, #E5E5E5, #fff);
		background: -moz-linear-gradient(top, #E5E5E5, #fff);
		background: linear-gradient(top, #E5E5E5, #fff);
	}
}

.btn_or {
	font-size: 50px;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.col-sm-6:hover .btn_or {
	color: #F79638;
}

/* ==========================================================================
   Dropdown Checkbox
   ========================================================================== */
.dropdown_check {
	display: block;
	top: calc(100% + 3px);
	left: 0;
	right: auto;

	&:after {
		left: 15px;
		right: auto;
	}

	.panel-heading .btn_help {
		float: right;
	    font-size: 16px;
	    margin: -2px -8px 0 0;
	    line-height: 18px;
	    width: 22px;
	    height: 22px;
	    padding: 0;
	    text-align: center;
	}

	li label {
		position: relative;
		display: block;
	    padding: 10px 40px 10px 20px;
	    clear: both;
	    font-weight: normal;
	    line-height: 1.42857;
	    color: #333333;
	    white-space: nowrap;
	    margin: 0;
	    cursor: pointer!important;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;

		input[type="checkbox"] {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			cursor: pointer;
			margin: 0;
		}

		span:after {
			content: "";
			display: none;
			position: absolute;
			right: 10px;
			top: calc(50% - 5px);
			width: 12px;
			height: 7px;
			border: solid 3px #3D4051;
			border-top: 0;
			border-right: 0;
			-webkit-transform: rotateZ(-45deg);
			-ms-transform: rotateZ(-45deg);
			transform: rotateZ(-45deg);
			-webkit-transition: all 0.2s ease-out;
			transition: all 0.2s ease-out;
		}

		input[type="checkbox"]:checked + span:after {
			display: block;
			border-color: #3D4051;
		}

		&:hover {
		    text-decoration: none;
			border-color: #fff;
		    color: #fff;
		    background-color: #009688;
		
			span:after {
				border-color: #fff!important;
			}
		}
	}
}

.more_filter i {
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	&.active {
		-webkit-transform: rotateZ(180deg);
		-moz-transform: rotateZ(180deg);
		transform: rotateZ(180deg);
	}
}

/* ==========================================================================
   Footer
   ========================================================================== */
.body-auth .footer_system {
	display: none;
}

.footer_system a {
	position: relative;
	display: inline-block;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	&:after {
		display: inline-block;
	    content: "";
	    width: 100%;
	    height: 2px;
	    background-color: #9EA0A8;
	    position: absolute;
	    left: 0;
	    bottom: -10px;
	    opacity: 0;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	}

	&:hover {
		color: #262B33;
		text-decoration: none!important;

		&:after {
			opacity: 1;
		    bottom: -1px;
		}
	}
}

/* ==========================================================================
   Helpers
   ========================================================================== */
.relative {
	position: relative;
}

.d_table {
	display: table;
}

.d_table_middle {
	display: table-cell;
	vertical-align: middle;
}

.d_table_cell {
	display: table-cell;
	vertical-align: middle;
}

.c_white {
	color: #fff!important;
}

.size_full {
	width: 100%;
	height: 100%;
}

.w_full {
	width: 100%;
}

.h_full_device {
	min-height: 100vh;
}

.w_50 {
	width: 50px;
}

.w_100 {
	width: 100px;
}

.w_130 {
	width: 130px;
}

.w_150 {
	width: 150px;
}

.t_center {
	text-align: center!important;
}

.t_right {
	text-align: right!important;
}

.t_left {
	text-align: left!important;
}

.text_normal {
	text-transform: none!important;
}

.f_size_25 {
	font-size: 25px;
}

.f_size_50 {
	font-size: 50px;
}

.c_default {
	cursor: default!important;
}

.bold {
	font-weight: bold;
}

.m_0_auto {
    margin: 0 auto;
}

.margin_0 {
	margin: 0!important;
}

.m-l-5 {
	margin-left: 5px;
}

.m-r-5 {
	margin-right: 5px;
}

.m_right_5 {
	margin-right: 5px;
}

.m_top_10 {
	margin-top: 10px!important;
}

.m_top_13 {
	margin-top: 13px;
}

.m_top_15 {
	margin-top: 15px!important;
}

.m_top_20 {
	margin-top: 20px;
}

.m_top_28 {
	margin-top: 28px;
}

.m_top_30 {
	margin-top: 30px;
}

.m_bottom_0 {
	margin-bottom: 0px;
}

.m_bottom_5 {
	margin-bottom: 5px;
}

.m_bottom_15 {
	margin-bottom: 15px;
}

.m_bottom_20 {
	margin-bottom: 20px;
}

.m_bottom_30 {
	margin-bottom: 30px;
}

.padding_0 {
	padding: 0!important;
}

.padding_20 {
	padding: 20px!important;
}

.padding_10 {
	padding: 10px!important;
}

.padding_30 {
	padding: 30px!important;
}

.p_top_0 {
	padding-top: 0;
}

.p_bottom_5 {
	padding-bottom: 5px!important;
}

/* ==========================================================================
   Responsive
   ========================================================================== */
@media (max-width: 991px) {
	.m_md_top_0 {
		margin-top: 0px!important;
	}

	.m_md_top_10 {
		margin-top: 10px!important;
	}
}

@media (max-width: 767px) {
	.app.nav-collapsed-min {
		.top-header {
			.logo .logo-text {
				display: inline-block;
			}

			.top-nav {
				padding: 64px 0!important;
			}
		}

		#nav-container {
			position: static;
			float: left;
			display: block;
		}

		.nav-container .nav > li > a {
			overflow: hidden;
		}
	}
	
	.ul_name > li {
		display: inline-block!important;
		float: right!important;
	}

	.ul_name .open .dropdown-menu {
	    position: absolute;
	    right: 15px;
	    top: calc(100% + 10px);
	    left: auto;
	    background-color: #fff;
	    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	}

	.or {
		position: relative;
	    left: auto;
	    right: auto;
	    top: auto;
	    margin: 20px 0 40px;

	    &:after,
	    &:before {
			width: 400%;
			height: 2px;
			top: calc(50% - 1px)!important;
			bottom: auto!important;
	    }

	    &:after {
			right: 100%!important;
			left: auto!important;
			background: -webkit-linear-gradient(right, #E5E5E5, transparent);
		    background: -o-linear-gradient(right, #E5E5E5, transparent);
		    background: -moz-linear-gradient(right, #E5E5E5, transparent);
		    background: linear-gradient(right, #E5E5E5, transparent);
		}

	    &:before {
			left: 100%!important;
			background: -webkit-linear-gradient(left, #E5E5E5, transparent);
		    background: -o-linear-gradient(left, #E5E5E5, transparent);
		    background: -moz-linear-gradient(left, #E5E5E5, transparent);
		    background: linear-gradient(left, #E5E5E5, transparent);
		}
	}

	.transparent_fillet {
		padding: 0 30px;
	}

	.m_sm_bottom_10 {
		margin-bottom: 10px;
	}

	.m_sm_bottom_15 {
		margin-bottom: 15px;
	}
}

/* ==========================================================================
   BACK END
   ========================================================================== */
.md-select-menu-container.md-active {
	z-index: 1000000;
}

table.fixedHeader-floating {
    z-index: 9999;
}

.modal-open .modal {
    z-index: 1050;
}

.dropzone {
	.dz-preview {
	    margin: 0;
	    padding: 7px;
	}

	.dz-preview .dz-image {
	    border-radius: 4px!important;
	}
}

/* ==========================================================================
   Custom Checkbox
   ========================================================================== */
.label_checkbox {
    display: flex;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    font-weight: normal;
    position: relative;

    input[type="checkbox"] {
	    position: absolute;
	    -webkit-appearance: none;
	    appearance: none;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    margin: 0;
	    opacity: 0;
	    cursor: pointer;
		
		& + span {
		    display: inline-block;
		    width: 18px;
		    height: 18px;
		    position: relative;
		}

		& + span:before {
		    content: "";
		    display: block;
		    width: 18px;
		    height: 18px;
		    border-radius: 1px;
		    border: 1px solid #3D4051;
		    -webkit-transition: all 0.1s ease-out;
		    transition: all 0.1s ease-out;
		}

		+ span:after {
		    content: "";
		    position: absolute;
		    bottom: 5px;
		    left: 6px;
		    display: block;
		    width: 5px;
		    height: 1px;
		    border: 2px solid #fff;
		    border-top-width: 0;
		    border-left-width: 0;
		    -webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		    -o-transform: rotate(45deg);
		    transform: rotate(45deg);
		    -webkit-transition: all 0.3s ease-out;
		    transition: all 0.3s ease-out;
		    opacity: 0;
		}

		&:checked + span {
			&:after {
			    height: 10px;
			    opacity: 1;
			}
		    &:before {
			    background-color: #3D4051;
			    border-color: #3D4051;
			}
		}

		&:disabled + span:before,
		&:disabled:checked + span:before {
		    border-color: #DCDCDC;
		}

		&:disabled:checked + span:before {
		    background-color: #DCDCDC;
		}

		&:disabled + span:after,
		&:disabled + span:before,
		&:disabled:checked + span:after,
		&:disabled:checked + span:before {
			cursor: no-drop;
			box-shadow: none!important;
		}

	}

	&:hover input[type="checkbox"] + span:before {
    	box-shadow: inset 1px 1px 0px #3D4051, inset -1px -1px 0px #3D4051;
    }

    &.check_white {
    	color: #fff;

    	input[type="checkbox"] {
    		+ span {

	    		&:before {
				    border: 1px solid #fff;
	    		}
	    	}

		    &:before {
			    background-color: #fff;
			    border-color: #fff;
			}
    	}
    }
}

/* ==========================================================================
   Cutom Radio
   ========================================================================== */
.label_radio {
    display: inline-block;
    margin: 0;
    cursor: pointer;
    font-weight: normal;
    position: relative;

	input[type="radio"] {
	    position: absolute;
	    -webkit-appearance: none;
	    appearance: none;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    margin: 0;
	    opacity: 0;
	    cursor: pointer;

		+ span {
		    display: inline-block;
		    width: 18px;
		    height: 18px;
		    position: relative;
		}

		+ span:before {
		    content: "";
		    display: block;
		    width: 18px;
		    height: 18px;
		    border-radius: 100%;
		    border: 1px solid #3D4051;
		    border-radius: 100%;
		    -webkit-transition: all 0.2s ease-out;
		    transition: all 0.2s ease-out;
		}
	}

	&:hover input[type="radio"] + span:before,
	input[type="radio"]:checked + span:before {
	    border: 2px solid #3D4051!important;
	}

	input[type="radio"] {
		+ span:after {
		    content: "";
		    position: absolute;
		    top: 4px;
		    left: 4px;
		    display: block;
		    width: 10px;
		    height: 10px;
		    background-color: #3D4051;
		    opacity: 0;
		    border-radius: 100%;
		    -webkit-transition: all 0.3s ease-out;
		    transition: all 0.3s ease-out;
		    transform: scale(0);
		    -webkit-transform: scale(0);
		    -moz-transform: scale(0);
		}

		&:checked + span:after {
		    opacity: 1;
		    transform: scale(1);
		    -webkit-transform: scale(1);
		    -moz-transform: scale(1);
		}

		&:disabled + span:after,
		&:disabled + span:before {
		    cursor: no-drop;
		}

		&:disabled + span:after {
		    background-color: #DCDCDC;
		}
	}

	input[type="radio"]:disabled + span:before,
	&:hover input[type="radio"]:disabled + span:before {
	    border: solid 1px #DCDCDC!important;
	}

	input[type="radio"]:disabled:checked + span:before {
	    border: solid 2px #DCDCDC!important;
	}
}

/* ==========================================================================
   Custom Switch
   ========================================================================== */
.label_switch {
    display: inline-block;
    margin: 0;
    cursor: pointer;
    font-weight: normal;
    position: relative;

	input[type="checkbox"] {
	    position: absolute;
	    -webkit-appearance: none;
	    appearance: none;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    margin: 0;
	    opacity: 0;
	    cursor: pointer;

		+ span {
		    display: inline-block;
		    width: 38px;
		    height: 18px;
		    position: relative;

			&:before {
			    content: "";
			    display: block;
			    width: 38px;
			    height: 22px;
			    border-radius: 20px;
			    border: 1px solid #dfdfdf;
			    -webkit-transition: all 0.1s ease-out;
			    transition: all 0.1s ease-out;
			}
		}
	}

	&:hover input[type="checkbox"] + span:before {
	    box-shadow: inset 0 0 15px #eee;
	}

	input[type="checkbox"] {
		+ span:after {
	    	content: "";
		    position: absolute;
		    top: 0px;
		    left: 0;
		    display: block;
		    width: 22px;
		    height: 22px;
		    -webkit-transition: all 0.3s ease-out;
		    transition: all 0.3s ease-out;
		    background: #fff;
		    border-radius: 20px;
		    border: solid 1px #dfdfdf;
		    box-shadow: 2px 1px 1px -2px rgba(0,0,0,0.4)
		}

		&:checked + span:after {
		    left: 18px;
		    border: solid 1px #5FBEAA;
		    box-shadow: none;
		}

		&:checked + span:before,
		&:disabled:checked + span:before {
		    border: 1px solid #5FBEAA;
		    background-color: #5FBEAA;
		}
	}

	&:hover input[type="checkbox"]:checked + span:before {
	    box-shadow: none;
	}

	input[type="checkbox"] {
		&:disabled + span {
		    opacity: 0.6;

			&:before {
			    background-color: #f7f7f7;
			}

			&:after {
			    background-color: #f3f3f3;
			}

			&:after {
			    background-color: #fff;
			}

			&:before,
			&:after {
			    cursor: no-drop;
			}
		}
	}
}

/* 	==========================================================================
	Loading Element
	==========================================================================	*/
.blockElement > div {
	font-size: 10px;
	margin: 50px auto;
	text-indent: -9999em;
	width: 2em;
	height: 2em;
	border-radius: 50%;
	background: #606371;
	background: -moz-linear-gradient(left, #606371 10%, rgba(126,228,255, 0) 42%);
	background: -webkit-linear-gradient(left, #606371 10%, rgba(126,228,255, 0) 42%);
	background: -o-linear-gradient(left, #606371 10%, rgba(126,228,255, 0) 42%);
	background: -ms-linear-gradient(left, #606371 10%, rgba(126,228,255, 0) 42%);
	background: linear-gradient(to right, #606371 10%, rgba(126,228,255, 0) 42%);
	position: relative;
	-webkit-animation: load3 0.3s infinite linear;
	animation: load3 0.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);

	&:before {
		width: 50%;
		height: 50%;
		background: #606371;
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	}

	&:after {
		background: #fff;
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* 	==========================================================================
	Loading Button
	==========================================================================	*/
.btn.blocked {
	position: relative;
	color: transparent!important;

	&:after {
	    content: "\f110"!important;
	    position: absolute!important;
	    left: calc(50% - 8px)!important;
	    top: calc(50% - 8px)!important;
	    width: 16px!important;
	    height: 16px!important;
	    line-height: 16px!important;
	    color: #fff!important;
	    text-align: center!important;
	    background: transparent!important;
	    display: inline-block!important;
	    font: normal normal normal 14px/1 FontAwesome;
	    font-size: 16px;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    -webkit-animation: spin 0.7s infinite linear!important;
	    -moz-animation: spin 0.7s infinite linear!important;
	    -ms-animation: spin 0.7s infinite linear!important;
	    animation: spin 0.7s infinite linear!important;
	}

	&.btn-default:after {
		color: #4c5667!important;
	}

	> div {
		display: none!important;
		opacity: 0!important;
	}
}

@-webkit-keyframes spin {
    from {
    	-webkit-transform: rotate(0deg);
    }
    to {
    	-webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
    	-moz-transform: rotate(0deg);
    }
    to {
    	-moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
    	-ms-transform: rotate(0deg);
    }
    to {
    	-ms-transform: rotate(360deg);
    }
}

@media (min-width: 1000px) {
    .page_entries {
        display: -webkit-flex;
        display: flex;
        width: 100%;

        > div {
            &:first-child {
                width: 100%;
                width: calc(100% - 300px);
            }

            &:last-child {
                min-width: 300px;
                max-width: 300px;
                padding-left: 15px;
            }
        }
    }
}