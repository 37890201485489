@charset "UTF-8";
.z-depth-1, .wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active, .header-container, .header-container .dropdown-menu.with-arrow.panel {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.z-depth-2, .page-signin .card,
.page-signup .card,
.page-forgot .card {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); }

@media print {
  .no-print {
    display: none; } }

md-backdrop.md-dialog-backdrop {
  z-index: 1040; }

.md-dialog-container {
  z-index: 91040; }

md-icon.zmdi {
  font-size: 24px; }

.ui-md-radio-group-inline md-radio-button {
  display: inline-block;
  margin: 0 10px 10px; }

md-radio-button[disabled] .md-on {
  background-color: #ccc; }

md-toast.md-toast-primary > .md-toast-content {
  background-color: #009688; }

.md-datepicker-calendar-pane .md-datepicker-input-mask {
  width: auto; }

md-toast {
  z-index: 999999 !important;
  position: fixed !important; }

.md-primary md-tabs-wrapper md-prev-button md-icon,
.md-primary md-tabs-wrapper md-next-button md-icon {
  color: #eeeeee; }

.chart-legend, .bar-legend, .line-legend, .pie-legend, .radar-legend, .polararea-legend, .doughnut-legend {
  list-style-type: none;
  margin-top: 5px;
  text-align: center; }
  .chart-legend li, .bar-legend li, .line-legend li, .pie-legend li, .radar-legend li, .polararea-legend li, .doughnut-legend li {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    margin-bottom: 4px;
    border-radius: 5px;
    padding: 2px 8px 2px 28px;
    font-size: smaller;
    cursor: default; }
    .chart-legend li span, .bar-legend li span, .line-legend li span, .pie-legend li span, .radar-legend li span, .polararea-legend li span, .doughnut-legend li span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 5px; }

.angular-ui-tree {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  list-style: none; }

.angular-ui-tree-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  border: 1px dashed #e2edeb;
  background-color: #E6F5FD;
  border-radius: 2px; }

.angular-ui-tree-handle {
  position: relative;
  display: block;
  margin: 5px 0;
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid #e2edeb;
  background: #fff;
  cursor: move;
  border-radius: 2px; }
  .angular-ui-tree-handle:hover .angular-ui-tree-icon-action {
    display: inline; }

.angular-ui-tree-icon:hover {
  cursor: pointer; }

.angular-ui-tree-icon-collapse {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 40px;
  margin: -10px 0 0 -10px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  font-weight: bold; }
  .angular-ui-tree-icon-collapse:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0; }
  .angular-ui-tree-icon-collapse.uncollapsed:before {
    content: '-'; }

.angular-ui-tree-icon-action {
  margin-left: 7px;
  display: none;
  color: #777777;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .angular-ui-tree-icon-action:hover {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    color: #3D4051; }

.angular-ui-tree-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #fff;
  background-size: 60px 60px;
  background-position: 0 0,30px 30px; }

.angular-ui-tree-nodes {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 30px; }

.angular-ui-tree-node, .angular-ui-tree-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px; }

.angular-ui-tree-hidden {
  display: none; }

.angular-ui-tree-drag {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: .8; }

table.responsive {
  border: 1px solid #ddd; }

@media only screen and (max-width: 767px) {
  table.responsive {
    margin-bottom: 0; }
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 35%;
    overflow: hidden;
    overflow-x: scroll;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc; }
  .pinned table {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: none;
    border-left: none;
    width: 100%; }
  .pinned table th, .pinned table td {
    white-space: nowrap; }
  .pinned td:last-child {
    border-bottom: 0; }
  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc; }
  div.table-wrapper div.scrollable {
    margin-left: 35%; }
  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden; }
  table.responsive td, table.responsive th {
    position: relative;
    white-space: nowrap;
    overflow: hidden; }
  table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td {
    display: none; } }

.easypiechart {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
  text-align: center;
  margin: 5px auto; }
  .easypiechart canvas {
    position: absolute;
    top: 0;
    left: 0; }
  .easypiechart .pie-percent {
    display: inline-block;
    line-height: 180px;
    font-size: 40px;
    font-weight: 300;
    color: #333; }
    .easypiechart .pie-percent:after {
      content: '%';
      margin-left: 0.1em;
      font-size: .6em; }
  .easypiechart.easypiechart-sm {
    width: 120px;
    height: 120px; }
    .easypiechart.easypiechart-sm .pie-percent {
      font-size: 28px;
      line-height: 120px; }

.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

.toast-message a,
.toast-message label {
  color: #ffffff; }

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/ }

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#toast-container > div {
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 2px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > :hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important; }

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important; }

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin: auto; }

.toast {
  background-color: #333; }

.toast-success {
  background-color: #76ab39; }

.toast-error {
  background-color: #f21f0f; }

.toast-info {
  background-color: #0098ab; }

.toast-warning {
  background-color: #fec000; }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden; }

.wizard a,
.tabcontrol a {
  outline: 0; }

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0; }

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em; }

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em; }

/*
    Wizard
*/
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .wizard > .steps > ul > li {
    width: 100%; }
    @media (min-width: 600px) {
      .wizard > .steps > ul > li {
        width: 25%; } }

.wizard.vertical > .steps {
  display: block;
  width: 100%; }
  @media (min-width: 600px) {
    .wizard.vertical > .steps {
      display: inline;
      float: left;
      width: 30%; } }
  .wizard.vertical > .steps > ul > li {
    float: none;
    width: 100%; }

.wizard.vertical > .content {
  width: 100%; }
  @media (min-width: 600px) {
    .wizard.vertical > .content {
      display: inline;
      float: left;
      margin: 0 2.5% 0.5em 2.5%;
      width: 65%; } }

.wizard > .steps .number {
  font-size: 1.429em; }

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left; }

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  border-radius: 2px; }

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
  box-shadow: none; }

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #009688;
  color: #fff;
  cursor: default; }

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #009688;
  color: #fff;
  opacity: .6; }

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #F44336;
  color: #fff; }

.wizard > .content {
  background-color: #eee;
  display: block;
  margin: 0.5em;
  min-height: 25em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%; }

.wizard > .content > .body ul {
  list-style: disc !important; }

.wizard > .content > .body ul > li {
  display: list-item; }

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%; }

.wizard > .content > .body input {
  display: block;
  border: 1px solid #ccc; }

.wizard > .content > .body input[type="checkbox"] {
  display: inline-block; }

.wizard > .content > .body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11; }

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em; }

.wizard > .content > .body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%; }

.wizard > .actions > ul {
  display: inline-block;
  text-align: right; }

.wizard > .actions > ul > li {
  margin: 0 0.5em; }

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em; }

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background-color: #009688;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  min-width: 100px;
  border-radius: 2px;
  text-align: center; }

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: #eee;
  color: #aaa; }

/*
    Tabcontrol
*/
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%; }

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1; }

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 2px;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topleft: 2px;
  -moz-border-radius-topright: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0; }

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0; }

.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none; }

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none; }

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px; }

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px; }

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%; }

.tabcontrol > .content > .body ul {
  list-style: disc !important; }

.tabcontrol > .content > .body ul > li {
  display: list-item; }

.jvectormap-label {
  position: absolute;
  display: none;
  border-radius: 2px;
  background: #262B33;
  color: #fff;
  font-size: 12px;
  padding: 6px; }

.jvectormap-zoomin, .jvectormap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 2px;
  background: #eeeeee;
  padding: 3px;
  color: #3D4051;
  width: 18px;
  height: 18px;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

.jvectormap-zoomin {
  top: 10px; }

.jvectormap-zoomout {
  top: 30px; }

.page-dashboard h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 30px; }

.page-dashboard .box-stats {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  .page-dashboard .box-stats.no-border .box-num {
    border: 0; }
  .page-dashboard .box-stats .box-desc {
    font-size: 12px;
    line-height: 26px;
    color: #A5AEB1;
    text-transform: uppercase; }
  .page-dashboard .box-stats .box-num {
    font-size: 42px;
    line-height: 80px;
    font-weight: 300; }
    @media screen and (min-width: 768px) {
      .page-dashboard .box-stats .box-num {
        border-right: 2px solid #EDF0F1; } }

.page-dashboard .metrics {
  margin-bottom: 55px; }
  .page-dashboard .metrics .metric {
    display: block;
    margin-bottom: 10px;
    font-size: 26px;
    color: #009688; }
  .page-dashboard .metrics .metric-info {
    text-transform: uppercase;
    font-size: 16px;
    color: #aaa; }

.page-dashboard .progressbar-container {
  margin-bottom: 25px; }

.page-dashboard .panel-box {
  position: relative;
  border-radius: 2px; }
  .page-dashboard .panel-box .panel-top,
  .page-dashboard .panel-box .panel-bottom {
    height: 100px;
    padding: 30px 15px;
    font-size: 40px;
    line-height: 1; }
  .page-dashboard .panel-box .panel-bottom {
    border-top: 1px solid #dfdfdf; }
  .page-dashboard .panel-box .panel-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center; }
    .page-dashboard .panel-box .panel-info span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: #fff;
      border: 1px solid #dfdfdf;
      font-size: 11px;
      color: #777777;
      border-radius: 1em; }
  .page-dashboard .panel-box .bg-light {
    background-color: #f9f9f9; }

.lt-ie9-bg {
  z-index: 11111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  color: #999;
  padding: 100px 20px;
  text-align: center;
  font-size: 26px; }
  .lt-ie9-bg a {
    color: #f5f5f5;
    border-bottom: 2px solid #fff; }
    .lt-ie9-bg a:hover {
      text-decoration: none; }

.non-display {
  display: none; }

.page {
  padding: 15px 30px; }
  .page > .ui-section:last-child {
    margin-bottom: 0; }

.page-form-ele h3 {
  margin: 0; }

.body-err {
  background-color: #262B33;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover; }

.page-err {
  position: relative;
  height: 100%; }
  .page-err .err-container {
    padding: 45px 10px 0; }
    @media (min-width: 768px) {
      .page-err .err-container {
        padding: 100px 0 0; } }
  .page-err .err {
    color: #fafafa; }
    .page-err .err h1 {
      margin-bottom: 35px;
      color: #fafafa;
      color: rgba(255, 255, 255, 0.8);
      font-size: 150px;
      font-weight: 300;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }
      @media (min-width: 768px) {
        .page-err .err h1 {
          font-size: 180px; } }
    .page-err .err h2 {
      color: #fafafa;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
      font-weight: 300;
      font-size: 28px;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .page-err .err h2 {
          font-size: 36px; } }
  .page-err .err-body {
    padding: 20px 10px; }
  .page-err .btn-goback {
    color: #ffffff;
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, 0.8); }
    .page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1); }
    .open .page-err .btn-goback.dropdown-toggle {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1); }
  .page-err .footer {
    position: absolute;
    bottom: 20px;
    width: 100%; }

.body-auth {
  background-color: #EDF0F1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover; }

.btn-minimal {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, 0.8); }
  .btn-minimal:hover, .btn-minimal:focus, .btn-minimal:active, .btn-minimal.active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1); }
  .open .btn-minimal.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1); }

.page-signin .wrapper,
.page-signup .wrapper,
.page-forgot .wrapper {
  padding: 0 10px; }

.page-signin .main-body,
.page-signup .main-body,
.page-forgot .main-body {
  max-width: 480px;
  margin: 10px auto; }

.page-signin .body-inner,
.page-signup .body-inner,
.page-forgot .body-inner {
  position: relative;
  padding: 20px; }
  .page-signin .body-inner:before,
  .page-signup .body-inner:before,
  .page-forgot .body-inner:before {
    z-index: -1;
    content: ' ';
    line-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #009688;
    height: 220px; }

.page-signin .additional-info,
.page-signup .additional-info,
.page-forgot .additional-info {
  text-align: center;
  font-size: 12px; }
  .page-signin .additional-info a,
  .page-signup .additional-info a,
  .page-forgot .additional-info a {
    color: #fafafa; }
  .page-signin .additional-info .divider-h,
  .page-signup .additional-info .divider-h,
  .page-forgot .additional-info .divider-h {
    border-right: 1px solid #fafafa;
    margin: 0 15px; }

.page-signin h1,
.page-signup h1,
.page-forgot h1 {
  font-weight: normal;
  color: #009688;
  font-size: 38px;
  margin-bottom: 40px; }
  .page-signin h1 a,
  .page-signup h1 a,
  .page-forgot h1 a {
    color: #009688; }

.page-signin .card .card-content,
.page-signup .card .card-content,
.page-forgot .card .card-content {
  padding: 30px 20px; }

.page-signin .ui-input-group .form-control,
.page-signup .ui-input-group .form-control,
.page-forgot .ui-input-group .form-control {
  color: #555555; }

.page-signin .form-group,
.page-signup .form-group,
.page-forgot .form-group {
  position: relative;
  margin: 0; }

.page-signin .wrapper,
.page-signup .wrapper,
.page-forgot .wrapper {
  margin-top: 50px; }
  @media (min-width: 768px) {
    .page-signin .wrapper,
    .page-signup .wrapper,
    .page-forgot .wrapper {
      margin-top: 150px; } }

.page-signin .logo,
.page-signup .logo,
.page-forgot .logo {
  font-size: 26px;
  font-weight: normal; }
  .page-signin .logo a:hover,
  .page-signup .logo a:hover,
  .page-forgot .logo a:hover {
    text-decoration: none; }

.page-profile {
  margin: -15px 0 0; }
  .page-profile .profile-header {
    position: relative;
    margin: 0 -30px 15px;
    padding: 50px 30px 90px;
    background: url("../images/background/1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover; }
  .page-profile .profile-img {
    display: inline-block;
    margin-right: 20px; }
    .page-profile .profile-img img {
      max-width: 120px;
      height: auto;
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2); }
  .page-profile .profile-social {
    display: inline-block; }
    .page-profile .profile-social > a {
      margin-right: 15px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .page-profile .profile-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 20px;
    padding: 10px 30px;
    color: #fafafa; }
    .page-profile .profile-info ul {
      margin: 0; }
  .page-profile img.media-object {
    border-radius: 2px; }

.page-invoice .invoice-wrapper {
  padding: 0 0 30px;
  background-color: #ffffff; }

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #ffffff; }
  .invoice-inner .invoice-sum li {
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 2px; }
  .invoice-inner .table.table-bordered {
    border: 0; }
  .invoice-inner .table .bg-dark > th {
    border: 0; }

.page-icons .page-header {
  border-bottom: 0; }

.page-icons .icon-material .zmdi {
  display: block;
  height: 80px;
  font-size: 1.5em;
  line-height: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.page-icons .icon-material .col-sm-4 {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  .page-icons .icon-material .col-sm-4:hover {
    color: #262B33; }
    .page-icons .icon-material .col-sm-4:hover .zmdi {
      font-size: 3em;
      color: #009688; }

.page-features .panel {
  border: 0; }

.page-features .feature-list-container .panel-body {
  background-color: #405161;
  padding: 30px 50px; }

.page-features .feature-list-container .col-xs-4 {
  margin-bottom: 50px; }

.page-form-ele .list-checkbox-radio li:nth-child(even) {
  margin-bottom: 20px; }

.page-form-ele .list-checkbox-radio li > div {
  float: left;
  margin-right: 10px; }

.demoslider-container {
  max-width: 600px;
  margin: auto; }

.page-grid .grid-structure .row {
  margin-top: 10px; }
  .page-grid .grid-structure .row .widget-container {
    margin-top: 5px;
    background: #eee;
    padding: 10px 15px 12px;
    font-size: 12px;
    min-height: 0;
    border-radius: 2px; }

.table.table-demo tr:first-child td {
  border-top: 0; }

.table.table-demo .type-info {
  vertical-align: middle; }

.whiteframedemoBasicUsage md-whiteframe {
  background: #fff;
  margin: 30px;
  height: 100px; }

@media screen and (max-width: 599px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 7px;
    height: 50px;
    background-color: #c8e4fa; }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.4em; } }

@media screen and (min-width: 600px) and (max-width: 959px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 20px;
    height: 75px; }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.6em; } }

@media screen and (min-width: 960px) and (max-width: 1199px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 20px;
    height: 90px;
    background-color: #fcddde; }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.9em; } }

@media screen and (min-width: 1200px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 25px;
    height: 100px;
    background-color: #F2FCE2; }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 1em; } }

.radioButtondemoMultiColumn md-divider {
  margin: 0 -15px; }

.radioButtondemoMultiColumn div.radioDemo2 {
  margin-bottom: 20px; }

.radioButtondemoMultiColumn h2 {
  margin-left: 15px; }

.radioButtondemoMultiColumn p {
  width: 400px;
  margin-top: 10px;
  margin-left: 10px;
  padding-top: 10px;
  border-top: 2px solid #ddd; }

.radioButtondemoMultiColumn .md-checked {
  background-color: #ECFAFB;
  border-radius: 2px; }

.radioButtondemoMultiColumn md-button.md-raised, .radioButtondemoMultiColumn button.md-raised {
  width: 200px; }

.radioButtondemoMultiColumn .row {
  border-bottom: 1px dashed #ddd; }

.radioButtondemoMultiColumn div.row:last-child {
  border-bottom: 0px dashed #ddd; }

.radioButtondemoMultiColumn .summary {
  width: 100%;
  padding-top: 10px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -5px; }

.radioButtondemoMultiColumn .title {
  font-weight: bolder; }

.radioButtondemoMultiColumn .selectedUser .md-checked {
  padding: 8px;
  width: 100px; }

.layout-boxed > .header-container .top-header {
  max-width: 1200px;
  margin: 0 auto; }

.layout-boxed > .main-container {
  max-width: 1200px;
  margin: 0 auto; }

.body-wide.app > .main-container > .nav-container,
.body-wide.app > .main-container > .content-container,
.body-wide .header-container > .top-header .logo,
.body-wide .header-container > .top-header .top-nav {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

html {
  position: relative;
  min-height: 100%;
  height: 100%;
  background: none; }

.app {
  position: relative;
  min-height: 100%;
  height: 100%; }
  .app > .header-container.header-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
    .app > .header-container.header-fixed + .main-container {
      padding-top: 128px; }
      @media (min-width: 768px) {
        .app > .header-container.header-fixed + .main-container {
          padding-top: 64px; } }
  .app > .main-container {
    position: relative;
    min-height: 100%;
    padding-bottom: 0; }
    .app > .main-container:before {
      content: " ";
      line-height: 0;
      z-index: -2;
      position: absolute;
      display: block;
      width: 100%;
      max-width: inherit;
      top: 0;
      bottom: 0;
      background-color: #efefef;
      box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2); }
    .app > .main-container > .nav-vertical:before {
      left: 0; }
    .app > .main-container > .nav-container {
      display: none;
      z-index: 11;
      position: static;
      float: left;
      width: 220px;
      -webkit-transition: width 0.25s ease-in-out;
      -moz-transition: width 0.25s ease-in-out;
      transition: width 0.25s ease-in-out; }
      @media (min-width: 768px) {
        .app > .main-container > .nav-container {
          display: block; } }
      .app > .main-container > .nav-container:before {
        z-index: -1;
        content: " ";
        line-height: 0;
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        width: inherit;
        background-color: inherit;
        border-width: inherit;
        border-style: inherit;
        border-color: inherit; }
      .app > .main-container > .nav-container.nav-fixed {
        position: fixed;
        top: 128px;
        bottom: 0;
        float: none; }
        @media (min-width: 768px) {
          .app > .main-container > .nav-container.nav-fixed {
            top: 64px; } }
    .app > .main-container > .content-container {
      overflow: hidden;
      min-height: 100%;
      margin-left: 0;
      -webkit-transition: margin-left 0.25s ease-in-out;
      -moz-transition: margin-left 0.25s ease-in-out;
      transition: margin-left 0.25s ease-in-out; }
      @media (min-width: 768px) {
        .app > .main-container > .content-container {
          margin-left: 220px; } }

#nav-container ul {
  padding-left: 0;
  list-style: none; }

#nav-container > .nav-wrapper {
  position: relative;
  width: 100%;
  height: 100%; }

.nav-container .md-button {
  text-align: left;
  min-width: 20px;
  text-transform: none; }

.nav-container .nav {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: -blackberry-touch; }
  .nav-container .nav .nav-title {
    margin: 15px 15px 10px;
    font-weight: normal;
    font-size: 12px; }
  .nav-container .nav .nav-divider {
    margin: 10px 0 10px 15px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent; }
  .nav-container .nav > li > a > i,
  .nav-container .nav > li > a > .nav-icon {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
  .nav-container .nav a {
    font-size: 13px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
    .nav-container .nav a:hover, .nav-container .nav a:focus, .nav-container .nav a:active {
      text-decoration: none; }
  .nav-container .nav > li {
    position: relative;
    margin: 0;
    text-align: left;
    font-weight: bold;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
    .nav-container .nav > li.open .icon-has-ul {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
    .nav-container .nav > li.li-small {
      font-size: 12px;
      font-weight: normal; }
      .nav-container .nav > li.li-small > a {
        padding: 10px 15px; }
        .nav-container .nav > li.li-small > a > i {
          font-size: 12px; }
    .nav-container .nav > li:first-child > a {
      border-top: 0; }
    .nav-container .nav > li > a {
      position: relative;
      white-space: nowrap;
      display: block;
      padding: 15px 15px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out; }
      .nav-container .nav > li > a .badge {
        position: absolute;
        top: 16px;
        right: 8px; }
      .nav-container .nav > li > a > .icon-has-ul {
        position: absolute;
        top: 15px;
        right: 0;
        font-size: 9px;
        line-height: 19px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
    .nav-container .nav > li > .icon-has-ul-h {
      display: none; }
  .nav-container .nav ul {
    display: none; }
    .nav-container .nav ul a {
      font-size: 13px; }
    .nav-container .nav ul li {
      position: relative;
      padding: 0; }
      .nav-container .nav ul li:last-child > a {
        border-bottom: 0; }
      .nav-container .nav ul li > a {
        position: relative;
        display: block;
        padding: 13px 0 13px 25px;
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out; }
        .nav-container .nav ul li > a:first-child {
          border-top: none; }
        .nav-container .nav ul li > a > i {
          font-size: 4px;
          line-height: 16px;
          margin-right: 16px;
          vertical-align: middle; }
        .nav-container .nav ul li > a > .badge {
          position: absolute;
          right: 8px;
          top: 12px; }

.nav-vertical {
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.12); }

@media (max-width: 767px) {
  .on-canvas #header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 12; }
  .on-canvas #nav-container {
    display: block;
    position: fixed;
    top: 128px;
    bottom: 0;
    float: none; }
  .on-canvas .main-container {
    overflow: hidden; }
    .on-canvas .main-container #content {
      margin-left: 220px;
      margin-right: -220px; } }

@media (min-width: 768px) {
  .app > .main-container > .nav-container.nav-horizontal {
    z-index: 9;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #d5d5d5; }
    .app > .main-container > .nav-container.nav-horizontal:before {
      border: 0; }
    .app > .main-container > .nav-container.nav-horizontal .nav-title,
    .app > .main-container > .nav-container.nav-horizontal .nav-divider,
    .app > .main-container > .nav-container.nav-horizontal .li-small {
      display: none !important; }
    .app > .main-container > .nav-container.nav-horizontal.nav-fixed {
      border-width: 0 1px 1px 0;
      left: 0;
      right: 0;
      bottom: auto; }
      .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav {
        text-align: center; }
        .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li {
          display: inline-block;
          float: none; }
          .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li.nav-title, .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li.nav-divider {
            display: none; }
          .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li > a {
            padding: 17.5px 28px 12.5px; }
      .app > .main-container > .nav-container.nav-horizontal.nav-fixed + .content-container {
        margin: 80px 0 0; } }

@media (min-width: 768px) {
  .main-container > .nav-container.nav-horizontal {
    float: none;
    width: auto; }
    .main-container > .nav-container.nav-horizontal + .content-container {
      margin-left: 0; }
  .nav-horizontal {
    background-color: #eee; }
    .nav-horizontal .slimScrollDiv {
      overflow: visible !important;
      height: auto !important; }
      .nav-horizontal .slimScrollDiv .slimScrollBar,
      .nav-horizontal .slimScrollDiv .slimScrollRail {
        display: none !important; }
    .nav-horizontal .nav {
      overflow: visible !important; }
      .nav-horizontal .nav > li {
        position: relative;
        float: left; }
        .nav-horizontal .nav > li:hover > a {
          background-color: #e1e1e1;
          color: #3D4051; }
        .nav-horizontal .nav > li:hover > i {
          color: #3D4051; }
        .nav-horizontal .nav > li.active > a {
          background-color: transparent;
          color: #009688; }
        .nav-horizontal .nav > li.active > .icon-has-ul-h {
          color: #009688; }
        .nav-horizontal .nav > li > a {
          padding: 15px 30px;
          text-align: center;
          font-weight: normal; }
          .nav-horizontal .nav > li > a > i {
            margin: 0;
            font-size: 26px;
            line-height: 1; }
          .nav-horizontal .nav > li > a > span {
            margin: 0;
            display: block;
            font-size: 12px; }
          .nav-horizontal .nav > li > a .badge {
            top: 15px; }
          .nav-horizontal .nav > li > a > .icon-has-ul {
            display: none; }
        .nav-horizontal .nav > li > .icon-has-ul-h {
          display: block;
          position: absolute;
          bottom: 16px;
          right: 10px;
          font-size: 10px; }
        .nav-horizontal .nav > li:hover > ul {
          display: block !important; }
        .nav-horizontal .nav > li > ul {
          z-index: 9;
          position: absolute;
          left: 0;
          top: 100%;
          min-width: 100%;
          width: auto;
          background-color: #e1e1e1;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px; }
          .nav-horizontal .nav > li > ul li {
            white-space: nowrap; }
            .nav-horizontal .nav > li > ul li:hover > a, .nav-horizontal .nav > li > ul li.active > a {
              color: #009688; }
            .nav-horizontal .nav > li > ul li > a {
              padding: 13px 40px 13px 20px; } }

@media (min-width: 768px) {
  .app.nav-collapsed-min .slimScrollDiv {
    overflow: visible !important;
    height: auto !important; }
    .app.nav-collapsed-min .slimScrollDiv .slimScrollBar,
    .app.nav-collapsed-min .slimScrollDiv .slimScrollRail {
      display: none !important; }
  .app.nav-collapsed-min #nav-container {
    width: 60px; }
  .app.nav-collapsed-min .content-container {
    margin-left: 60px; }
  .app.nav-collapsed-min #nav-wrapper {
    overflow: visible !important; }
  .app.nav-collapsed-min #nav {
    overflow: visible !important; }
    .app.nav-collapsed-min #nav > li.li-small > a > i {
      font-size: 12px; }
    .app.nav-collapsed-min #nav > li > a {
      padding: 15px 15px;
      text-align: center; }
      .app.nav-collapsed-min #nav > li > a > i {
        margin: 0;
        font-size: 22px; }
      .app.nav-collapsed-min #nav > li > a > span {
        display: none; }
    .app.nav-collapsed-min #nav > li .icon-has-ul {
      display: none; }
    .app.nav-collapsed-min #nav > li > ul {
      z-index: 9;
      position: absolute;
      left: 100%;
      top: 0;
      width: 220px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); }
    .app.nav-collapsed-min #nav > li:hover > ul, .app.nav-collapsed-min #nav > li.open > ul {
      display: block !important; }
  .app.nav-collapsed-min .nav-title {
    display: none; }
  .app.nav-collapsed-min .nav-horizontal#nav-container {
    width: auto; }
  .app.nav-collapsed-min .nav-horizontal + .content-container {
    margin: 0; }
  .app.nav-collapsed-min .nav-horizontal.nav-fixed + .content-container {
    margin: 50px 0 0; }
  .app.nav-collapsed-min .nav-horizontal #nav > li > .icon-has-ul-h {
    display: none; }
  .app.nav-collapsed-min .nav-horizontal #nav > li > ul {
    left: 0;
    top: 100%; } }

.nav-container:before {
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  width: 2px;
  z-index: 2; }

.nav-container.bg-white:before {
  box-shadow: inset -1px 0 1px rgba(255, 255, 255, 0.3); }

.nav-container.bg-white .nav a {
  color: #3D4051; }

.nav-container.bg-white .nav .nav-title {
  color: #999; }

.nav-container.bg-white .nav .nav-divider {
  border-color: #eeeeee; }

.nav-container.bg-white .nav > li:hover > a {
  background-color: transparent;
  color: #009688; }
  .nav-container.bg-white .nav > li:hover > a > .icon-has-ul {
    color: #009688; }

.nav-container.bg-white .nav > li:hover > .icon-has-ul-h {
  color: #009688; }

.nav-container.bg-white .nav > li.active > a {
  color: #009688; }
  .nav-container.bg-white .nav > li.active > a > .icon-has-ul {
    color: #009688; }

.nav-container.bg-white .nav > li > a:focus {
  background-color: transparent; }

.nav-container.bg-white .nav ul {
  background-color: #f6f6f6; }
  .nav-container.bg-white .nav ul > li:hover > a, .nav-container.bg-white .nav ul > li.active > a {
    color: #009688; }

.nav-container.bg-white.nav-vertical .nav > li.open > a {
  background-color: #f6f6f6;
  color: #009688; }

.nav-container.bg-white.nav-vertical .nav > li.open > .icon-has-ul,
.nav-container.bg-white.nav-vertical .nav > li.open > .icon-has-ul-h {
  color: #009688; }

.nav-container.bg-dark {
  background-color: #262B33; }
  .nav-container.bg-dark:before {
    box-shadow: inset -1px 0 1px rgba(0, 0, 0, 0.3); }
  .nav-container.bg-dark .nav a {
    color: #ccc;
    color: rgba(255, 255, 255, 0.5); }
  .nav-container.bg-dark .nav .nav-title {
    color: #666; }
  .nav-container.bg-dark .nav .nav-divider {
    border-color: #1b1f24; }
  .nav-container.bg-dark .nav > li:hover > a {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8); }
    .nav-container.bg-dark .nav > li:hover > a > .icon-has-ul {
      color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark .nav > li:hover > .icon-has-ul-h {
    color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark .nav > li.active > a {
    color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark .nav > li.active > .icon-has-ul {
    color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark .nav > li > a:focus {
    background-color: transparent; }
  .nav-container.bg-dark .nav ul {
    background-color: #1f242a; }
    .nav-container.bg-dark .nav ul > li:hover > a, .nav-container.bg-dark .nav ul > li.active > a {
      color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark.nav-vertical .nav > li.open > a {
    background-color: #1f242a;
    color: rgba(255, 255, 255, 0.8); }
    .nav-container.bg-dark.nav-vertical .nav > li.open > a > .icon-has-ul {
      color: rgba(255, 255, 255, 0.8); }
  .nav-container.bg-dark.nav-vertical .nav > li.open > .icon-has-ul-h {
    color: rgba(255, 255, 255, 0.8); }

@media (min-width: 768px) {
  .app.nav-collapsed-min .nav-container.bg-white .nav > li:hover a {
    background-color: #f6f6f6; }
  .app.nav-collapsed-min .nav-container.bg-dark .nav > li:hover a {
    background-color: #1f242a; } }

@media (max-width: 767px) {
  .nav-horizontal .nav > li.open > a {
    color: #009688; }
  .nav-horizontal .nav > li.open > i {
    color: #009688; } }

@media (min-width: 768px) {
  .app.nav-collapsed-min .top-header .logo {
    width: 60px; } }

.app.nav-collapsed-min .top-header .logo .logo-text {
  display: none; }

.app.nav-collapsed-min .top-header .top-nav {
  padding: 0 0 0 60px; }

.header-container .md-button {
  min-width: 66px; }

.header-container > .top-header a {
  color: #aaa; }
  .header-container > .top-header a:hover {
    text-decoration: none; }

.header-container > .top-header .dropdown-menu a {
  color: #3D4051; }

.header-container > .top-header .hidden-mobile {
  display: none; }
  @media (min-width: 480px) {
    .header-container > .top-header .hidden-mobile {
      display: inline; } }

.header-container > .top-header .logo {
  position: absolute;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
  -webkit-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out; }
  @media (min-width: 768px) {
    .header-container > .top-header .logo {
      display: inline-block;
      width: 220px;
      border-right: 1px solid rgba(255, 255, 255, 0.06); } }
  .header-container > .top-header .logo .logo-icon {
    font-size: 20px; }
  .header-container > .top-header .logo > a {
    font-size: 24px;
    vertical-align: middle;
    white-space: nowrap; }

.header-container > .top-header .menu-button {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 46px;
  padding: 10px 12px;
  border-radius: 4px; }
  .header-container > .top-header .menu-button:hover {
    cursor: pointer;
    background-color: #00635a; }
  .header-container > .top-header .menu-button .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff; }
  .header-container > .top-header .menu-button .icon-bar + .icon-bar {
    margin-top: 4px; }
  @media (min-width: 768px) {
    .header-container > .top-header .menu-button {
      display: none; } }

.header-container > .top-header .admin-options {
  z-index: 20;
  line-height: 20px; }

.header-container > .top-header .skin-options .panel-body {
  min-width: 330px;
  background-color: #f9f9f9; }

.header-container > .top-header .dropdown-menu.panel {
  padding: 0;
  white-space: nowrap; }
  .header-container > .top-header .dropdown-menu.panel .panel-heading,
  .header-container > .top-header .dropdown-menu.panel .panel-footer {
    padding: 10px 15px; }
  .header-container > .top-header .dropdown-menu.panel .list-group-item {
    padding: 10px 15px; }
    .header-container > .top-header .dropdown-menu.panel .list-group-item > a {
      display: block; }
    .header-container > .top-header .dropdown-menu.panel .list-group-item > p {
      margin-bottom: 0; }
    .header-container > .top-header .dropdown-menu.panel .list-group-item > .divider {
      background-color: transparent; }
  .header-container > .top-header .dropdown-menu.panel .media .media-body {
    padding-right: 75px; }

.header-container > .top-header .top-nav {
  width: 100%;
  height: 64px;
  padding: 64px 0 0;
  -webkit-transition: padding 0.25s ease-in-out;
  -moz-transition: padding 0.25s ease-in-out;
  transition: padding 0.25s ease-in-out; }
  @media (min-width: 768px) {
    .header-container > .top-header .top-nav {
      padding: 0 0 0 220px; } }
  .header-container > .top-header .top-nav > ul {
    margin-bottom: 0; }
  .header-container > .top-header .top-nav .nav-left,
  .header-container > .top-header .top-nav .nav-right {
    font-size: 16px;
    line-height: 20px; }
    .header-container > .top-header .top-nav .nav-left > li,
    .header-container > .top-header .top-nav .nav-right > li {
      float: left; }
      .header-container > .top-header .top-nav .nav-left > li.nav-profile > a,
      .header-container > .top-header .top-nav .nav-right > li.nav-profile > a {
        padding: 17px 15px; }
      .header-container > .top-header .top-nav .nav-left > li.nav-profile .hidden-xs,
      .header-container > .top-header .top-nav .nav-right > li.nav-profile .hidden-xs {
        padding-right: 8px; }
      .header-container > .top-header .top-nav .nav-left > li.nav-profile i,
      .header-container > .top-header .top-nav .nav-right > li.nav-profile i {
        width: 18px;
        font-size: 18px;
        margin-right: 5px;
        line-height: 20px; }
      .header-container > .top-header .top-nav .nav-left > li > .toggle-min,
      .header-container > .top-header .top-nav .nav-right > li > .toggle-min {
        display: none; }
        @media (min-width: 768px) {
          .header-container > .top-header .top-nav .nav-left > li > .toggle-min,
          .header-container > .top-header .top-nav .nav-right > li > .toggle-min {
            display: block; } }
      .header-container > .top-header .top-nav .nav-left > li a:hover, .header-container > .top-header .top-nav .nav-left > li a:focus,
      .header-container > .top-header .top-nav .nav-right > li a:hover,
      .header-container > .top-header .top-nav .nav-right > li a:focus {
        text-decoration: none; }
      .header-container > .top-header .top-nav .nav-left > li > .btn-group > a,
      .header-container > .top-header .top-nav .nav-left > li > a,
      .header-container > .top-header .top-nav .nav-right > li > .btn-group > a,
      .header-container > .top-header .top-nav .nav-right > li > a {
        position: relative;
        display: block;
        height: 64px;
        padding: 22px 16px; }
        @media (min-width: 768px) {
          .header-container > .top-header .top-nav .nav-left > li > .btn-group > a,
          .header-container > .top-header .top-nav .nav-left > li > a,
          .header-container > .top-header .top-nav .nav-right > li > .btn-group > a,
          .header-container > .top-header .top-nav .nav-right > li > a {
            padding: 22px 20px; } }
        .header-container > .top-header .top-nav .nav-left > li > .btn-group > a > i,
        .header-container > .top-header .top-nav .nav-left > li > a > i,
        .header-container > .top-header .top-nav .nav-right > li > .btn-group > a > i,
        .header-container > .top-header .top-nav .nav-right > li > a > i {
          font-size: 18px; }
        .header-container > .top-header .top-nav .nav-left > li > .btn-group > a .badge,
        .header-container > .top-header .top-nav .nav-left > li > a .badge,
        .header-container > .top-header .top-nav .nav-right > li > .btn-group > a .badge,
        .header-container > .top-header .top-nav .nav-right > li > a .badge {
          position: absolute;
          top: 6px;
          right: 3px; }
          @media (min-width: 768px) {
            .header-container > .top-header .top-nav .nav-left > li > .btn-group > a .badge,
            .header-container > .top-header .top-nav .nav-left > li > a .badge,
            .header-container > .top-header .top-nav .nav-right > li > .btn-group > a .badge,
            .header-container > .top-header .top-nav .nav-right > li > a .badge {
              background-color: transparent;
              color: #aaa; } }
      .header-container > .top-header .top-nav .nav-left > li ul.dropdown-menu a:hover,
      .header-container > .top-header .top-nav .nav-right > li ul.dropdown-menu a:hover {
        background-color: #009688;
        color: #fff; }
      .header-container > .top-header .top-nav .nav-left > li ul.dropdown-menu .glyphicon,
      .header-container > .top-header .top-nav .nav-right > li ul.dropdown-menu .glyphicon {
        margin-right: 10px; }
  .header-container > .top-header .top-nav .search-box {
    max-width: 180px;
    padding: 12px 0; }
    .header-container > .top-header .top-nav .search-box i {
      font-size: 18px;
      line-height: 20px; }
    .header-container > .top-header .top-nav .search-box .input-group-addon {
      padding: 6px 0 6px 12px;
      border: none;
      background-color: transparent; }
    .header-container > .top-header .top-nav .search-box .form-control {
      border: none;
      background: transparent; }
    .header-container > .top-header .top-nav .search-box .input-bar {
      position: absolute;
      display: block;
      width: 90%;
      bottom: 0;
      left: 5%; }
    .header-container > .top-header .top-nav .search-box .input-bar:before,
    .header-container > .top-header .top-nav .search-box .input-bar:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 0;
      bottom: 1px;
      -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      -moz-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    .header-container > .top-header .top-nav .search-box .input-bar:before {
      left: 50%; }
    .header-container > .top-header .top-nav .search-box .input-bar:after {
      right: 50%; }
    .header-container > .top-header .top-nav .search-box input:focus ~ .input-bar:before,
    .header-container > .top-header .top-nav .search-box input:focus ~ .input-bar:after {
      width: 50%; }
  .header-container > .top-header .top-nav .nav-left {
    float: left;
    padding: 0; }
    .header-container > .top-header .top-nav .nav-left > li {
      border-right: 1px solid rgba(255, 255, 255, 0.06); }
  .header-container > .top-header .top-nav .nav-right {
    padding: 0; }
    .header-container > .top-header .top-nav .nav-right > li > a {
      text-align: center;
      border-left: 1px solid rgba(255, 255, 255, 0.06); }
    .header-container > .top-header .top-nav .nav-right > li:last-child {
      border-right: 1px solid rgba(255, 255, 255, 0.06); }
      .header-container > .top-header .top-nav .nav-right > li:last-child .dropdown-menu.pull-right {
        right: 10px; }

.header-container > .top-header .langs .active-flag .flag {
  margin-top: -5px; }

.header-container > .top-header .langs .list-langs a {
  position: relative;
  padding: 8px 20px 8px 57px; }
  .header-container > .top-header .langs .list-langs a .flag {
    position: absolute;
    top: 2px;
    left: 15px; }

.skin-check {
  position: relative;
  display: block; }
  .skin-check input[type="radio"] {
    display: none; }
  .skin-check input[type="radio"] + span:hover {
    cursor: pointer; }
  .skin-check input[type="radio"] + span {
    position: relative; }
    .skin-check input[type="radio"] + span > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 30px;
      color: #fff; }
  .skin-check input[type="radio"]:checked + span > .overlay {
    display: block; }

.skin-item {
  overflow: hidden;
  display: block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px; }
  .skin-item > span {
    display: block;
    float: left;
    width: 50%;
    height: 20px; }
  .skin-item .item-header {
    height: 10px; }

.header-container .dropdown {
  color: #3D4051; }
  .header-container .dropdown a {
    color: #3D4051; }
    .header-container .dropdown a:hover {
      color: #3D4051; }

.header-container.bg-white {
  color: #3D4051; }
  .header-container.bg-white .input-group-addon,
  .header-container.bg-white a {
    color: #3D4051; }
    .header-container.bg-white .input-group-addon:hover,
    .header-container.bg-white a:hover {
      color: #3D4051; }
  .header-container.bg-white .search-box .input-bar:before,
  .header-container.bg-white .search-box .input-bar:after {
    background: #3D4051; }

.header-container.bg-dark {
  color: #fafafa; }
  .header-container.bg-dark .input-group-addon,
  .header-container.bg-dark a {
    color: #fafafa; }
    .header-container.bg-dark .input-group-addon:hover,
    .header-container.bg-dark a:hover {
      color: #fafafa; }
  .header-container.bg-dark .search-box .form-control {
    color: #fafafa; }
  .header-container.bg-dark .search-box .input-bar:before,
  .header-container.bg-dark .search-box .input-bar:after {
    background: #fafafa; }

.header-container.bg-primary, .header-container.bg-success, .header-container.bg-info, .header-container.bg-info-alt, .header-container.bg-warning, .header-container.bg-danger {
  color: #fafafa; }
  .header-container.bg-primary a, .header-container.bg-success a, .header-container.bg-info a, .header-container.bg-info-alt a, .header-container.bg-warning a, .header-container.bg-danger a {
    color: #fafafa; }
    .header-container.bg-primary a:hover, .header-container.bg-success a:hover, .header-container.bg-info a:hover, .header-container.bg-info-alt a:hover, .header-container.bg-warning a:hover, .header-container.bg-danger a:hover {
      color: #fafafa; }
  .header-container.bg-primary .dropdown-menu, .header-container.bg-success .dropdown-menu, .header-container.bg-info .dropdown-menu, .header-container.bg-info-alt .dropdown-menu, .header-container.bg-warning .dropdown-menu, .header-container.bg-danger .dropdown-menu {
    color: #3D4051; }
    .header-container.bg-primary .dropdown-menu a, .header-container.bg-success .dropdown-menu a, .header-container.bg-info .dropdown-menu a, .header-container.bg-info-alt .dropdown-menu a, .header-container.bg-warning .dropdown-menu a, .header-container.bg-danger .dropdown-menu a {
      color: #3D4051; }
      .header-container.bg-primary .dropdown-menu a:hover, .header-container.bg-success .dropdown-menu a:hover, .header-container.bg-info .dropdown-menu a:hover, .header-container.bg-info-alt .dropdown-menu a:hover, .header-container.bg-warning .dropdown-menu a:hover, .header-container.bg-danger .dropdown-menu a:hover {
        color: #3D4051; }
  .header-container.bg-primary .input-group-addon, .header-container.bg-success .input-group-addon, .header-container.bg-info .input-group-addon, .header-container.bg-info-alt .input-group-addon, .header-container.bg-warning .input-group-addon, .header-container.bg-danger .input-group-addon {
    color: #eeeeee; }
  .header-container.bg-primary ::-webkit-input-placeholder, .header-container.bg-success ::-webkit-input-placeholder, .header-container.bg-info ::-webkit-input-placeholder, .header-container.bg-info-alt ::-webkit-input-placeholder, .header-container.bg-warning ::-webkit-input-placeholder, .header-container.bg-danger ::-webkit-input-placeholder {
    color: #eaeaea; }
  .header-container.bg-primary :-moz-placeholder, .header-container.bg-success :-moz-placeholder, .header-container.bg-info :-moz-placeholder, .header-container.bg-info-alt :-moz-placeholder, .header-container.bg-warning :-moz-placeholder, .header-container.bg-danger :-moz-placeholder {
    color: #eaeaea; }
  .header-container.bg-primary ::-moz-placeholder, .header-container.bg-success ::-moz-placeholder, .header-container.bg-info ::-moz-placeholder, .header-container.bg-info-alt ::-moz-placeholder, .header-container.bg-warning ::-moz-placeholder, .header-container.bg-danger ::-moz-placeholder {
    color: #eaeaea; }
  .header-container.bg-primary :-ms-input-placeholder, .header-container.bg-success :-ms-input-placeholder, .header-container.bg-info :-ms-input-placeholder, .header-container.bg-info-alt :-ms-input-placeholder, .header-container.bg-warning :-ms-input-placeholder, .header-container.bg-danger :-ms-input-placeholder {
    color: #eaeaea; }
  .header-container.bg-primary .top-nav .nav-left > li > a .badge, .header-container.bg-success .top-nav .nav-left > li > a .badge, .header-container.bg-info .top-nav .nav-left > li > a .badge, .header-container.bg-info-alt .top-nav .nav-left > li > a .badge, .header-container.bg-warning .top-nav .nav-left > li > a .badge, .header-container.bg-danger .top-nav .nav-left > li > a .badge {
    color: #eaeaea; }
  .header-container.bg-primary .search-box .form-control, .header-container.bg-success .search-box .form-control, .header-container.bg-info .search-box .form-control, .header-container.bg-info-alt .search-box .form-control, .header-container.bg-warning .search-box .form-control, .header-container.bg-danger .search-box .form-control {
    color: #fafafa; }
  .header-container.bg-primary .search-box .input-bar:before,
  .header-container.bg-primary .search-box .input-bar:after, .header-container.bg-success .search-box .input-bar:before,
  .header-container.bg-success .search-box .input-bar:after, .header-container.bg-info .search-box .input-bar:before,
  .header-container.bg-info .search-box .input-bar:after, .header-container.bg-info-alt .search-box .input-bar:before,
  .header-container.bg-info-alt .search-box .input-bar:after, .header-container.bg-warning .search-box .input-bar:before,
  .header-container.bg-warning .search-box .input-bar:after, .header-container.bg-danger .search-box .input-bar:before,
  .header-container.bg-danger .search-box .input-bar:after {
    background: #fafafa; }

.header-container > .top-header .logo {
  border: 1px solid rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) {
    .header-container > .top-header .logo {
      border: 0; } }
  .header-container > .top-header .logo.bg-white {
    color: #262B33; }
    .header-container > .top-header .logo.bg-white a {
      color: #262B33; }
      .header-container > .top-header .logo.bg-white a:hover {
        color: #262B33; }
    .header-container > .top-header .logo.bg-white + .menu-button .icon-bar {
      background-color: #3D4051; }
    .header-container > .top-header .logo.bg-white + .menu-button:hover .icon-bar {
      background-color: #ffffff; }
  .header-container > .top-header .logo.bg-dark, .header-container > .top-header .logo.bg-primary, .header-container > .top-header .logo.bg-success, .header-container > .top-header .logo.bg-info, .header-container > .top-header .logo.bg-info-alt, .header-container > .top-header .logo.bg-warning, .header-container > .top-header .logo.bg-danger {
    color: #fafafa; }
    .header-container > .top-header .logo.bg-dark a, .header-container > .top-header .logo.bg-primary a, .header-container > .top-header .logo.bg-success a, .header-container > .top-header .logo.bg-info a, .header-container > .top-header .logo.bg-info-alt a, .header-container > .top-header .logo.bg-warning a, .header-container > .top-header .logo.bg-danger a {
      color: #fafafa; }
      .header-container > .top-header .logo.bg-dark a:hover, .header-container > .top-header .logo.bg-primary a:hover, .header-container > .top-header .logo.bg-success a:hover, .header-container > .top-header .logo.bg-info a:hover, .header-container > .top-header .logo.bg-info-alt a:hover, .header-container > .top-header .logo.bg-warning a:hover, .header-container > .top-header .logo.bg-danger a:hover {
        color: #fafafa; }

.preloaderbar {
  z-index: 10000000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  margin-bottom: -4px; }
  .preloaderbar .bar {
    position: absolute;
    width: 100%;
    height: 0;
    text-indent: -9999px;
    background-color: #23b7e5; }
    .preloaderbar .bar:before {
      position: absolute;
      right: 50%;
      left: 50%;
      height: 4px;
      background-color: inherit;
      content: ""; }
  .preloaderbar.active {
    -webkit-animation: changebar 2.25s infinite 0.75s;
    -moz-animation: changebar 2.25s infinite 0.75s;
    animation: changebar 2.25s infinite 0.75s; }
  .preloaderbar.active .bar {
    -webkit-animation: changebar 2.25s infinite;
    -moz-animation: changebar 2.25s infinite;
    animation: changebar 2.25s infinite; }
  .preloaderbar.active .bar:before {
    -webkit-animation: movingbar 0.75s infinite;
    -moz-animation: movingbar 0.75s infinite;
    animation: movingbar 0.75s infinite; }

@-webkit-keyframes movingbar {
  0% {
    right: 50%;
    left: 50%; }
  99.9% {
    right: 0;
    left: 0; }
  100% {
    right: 50%;
    left: 50%; } }

@-moz-keyframes movingbar {
  0% {
    right: 50%;
    left: 50%; }
  99.9% {
    right: 0;
    left: 0; }
  100% {
    right: 50%;
    left: 50%; } }

@keyframes movingbar {
  0% {
    right: 50%;
    left: 50%; }
  99.9% {
    right: 0;
    left: 0; }
  100% {
    right: 50%;
    left: 50%; } }

@-webkit-keyframes changebar {
  0% {
    background-color: #8BC34A; }
  33.3% {
    background-color: #8BC34A; }
  33.33% {
    background-color: #FFCA28; }
  66.6% {
    background-color: #FFCA28; }
  66.66% {
    background-color: #F44336; }
  99.9% {
    background-color: #F44336; } }

@-moz-keyframes changebar {
  0% {
    background-color: #8BC34A; }
  33.3% {
    background-color: #8BC34A; }
  33.33% {
    background-color: #FFCA28; }
  66.6% {
    background-color: #FFCA28; }
  66.66% {
    background-color: #F44336; }
  99.9% {
    background-color: #F44336; } }

@keyframes changebar {
  0% {
    background-color: #8BC34A; }
  33.3% {
    background-color: #8BC34A; }
  33.33% {
    background-color: #FFCA28; }
  66.6% {
    background-color: #FFCA28; }
  66.66% {
    background-color: #F44336; }
  99.9% {
    background-color: #F44336; } }

body {
  background: #efefef; }

.body-auth.app > .main-container:before,
.body-err.app > .main-container:before {
  background: none; }

@media (min-width: 768px) {
  .body-auth .page-signin,
  .body-auth .page-signup,
  .body-auth .page-forgot,
  .body-auth .page-err,
  .body-err .page-signin,
  .body-err .page-signup,
  .body-err .page-forgot,
  .body-err .page-err {
    margin-right: 220px; } }

@media (min-width: 768px) {
  .body-auth.nav-collapsed-min .page-signin,
  .body-auth.nav-collapsed-min .page-signup,
  .body-auth.nav-collapsed-min .page-forgot,
  .body-auth.nav-collapsed-min .page-err,
  .body-err.nav-collapsed-min .page-signin,
  .body-err.nav-collapsed-min .page-signup,
  .body-err.nav-collapsed-min .page-forgot,
  .body-err.nav-collapsed-min .page-err {
    margin-right: 60px; } }

@media (min-width: 768px) {
  .body-auth .nav-horizontal + .content-container .page-signin,
  .body-auth .nav-horizontal + .content-container .page-signup,
  .body-auth .nav-horizontal + .content-container .page-forgot,
  .body-auth .nav-horizontal + .content-container .page-err,
  .body-err .nav-horizontal + .content-container .page-signin,
  .body-err .nav-horizontal + .content-container .page-signup,
  .body-err .nav-horizontal + .content-container .page-forgot,
  .body-err .nav-horizontal + .content-container .page-err {
    margin-right: 0; } }

.body-wide #header,
.body-wide #nav-container {
  display: none; }

.body-wide > .main-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0; }

.body-wide #content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 0; }

.body-wide.on-canvas .main-container #content {
  margin-left: 0;
  margin-right: 0; }

/* ==========================================================================
   Bases
   ========================================================================== */
.page {
  min-height: calc(100vh - 115px); }

.logo-text,
.logo {
  font-family: 'Ruda', sans-serif; }

.logo_login {
  max-width: 200px; }

.logo-icon img {
  margin-top: -5px; }

.name_top {
  text-transform: uppercase;
  font-weight: 500;
  padding: 22px 20px !important; }

.body-auth .content-container,
.body-auth .page-signin,
.body-auth .page-forgot {
  margin: 0 !important; }

.app > .main-container > .content-container {
  overflow: visible; }

.header-container > .top-header .menu-button {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .header-container > .top-header .menu-button .icon-bar {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .header-container > .top-header .menu-button:hover {
    background-color: #EFEFEF; }
    .header-container > .top-header .menu-button:hover .icon-bar {
      background-color: #262B33; }

.breadcamb a {
  color: #3D4051;
  text-decoration: none; }

.breadcamb a:hover,
.breadcamb a:focus {
  color: #3D4051;
  text-decoration: underline; }

.breadcamb i {
  font-size: 10px;
  color: #bbb;
  margin: 0 2px; }

.row {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .row > div[class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px; }

.form-horizontal label {
  font-weight: normal; }

.form-control {
  height: 36px;
  padding: 7px 12px !important; }
  .form-control:focus {
    background-color: #F2F7F7;
    border-color: #E2E8EC; }
  .form-control.disabled, .form-control.disabled:focus {
    background-color: #eee; }

.input-sm {
  height: 30px !important;
  padding: 5px !important; }

.has-error .form-control,
.has-error .form-control:focus,
.has-error button.btn.dropdown-toggle.btn-white {
  border-color: #E8B5B1;
  box-shadow: none;
  background: #FFF8F8; }

.has-error span.error {
  margin: 2px 0 0;
  color: #EC574B;
  font-size: 11px; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  max-width: 100%;
  z-index: 9999; }

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important; }

.bootstrap-select.btn-group .no-results {
  padding: 10px !important;
  margin: 0 !important; }

.select_vue {
  position: relative; }
  .select_vue .btn_clear_result {
    background: #fff;
    color: #ccc;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    top: 8px;
    z-index: 99;
    font-size: 10px;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
    .select_vue .btn_clear_result:hover {
      color: #333; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 6px !important; }

button.btn.dropdown-toggle.btn-white {
  background: #fff;
  border: solid 1px #CBD5DD;
  font-weight: normal;
  padding-right: 24px !important; }
  button.btn.dropdown-toggle.btn-white span {
    white-space: nowrap;
    text-overflow: ellipsis; }
  button.btn.dropdown-toggle.btn-white:focus {
    background-color: #F2F7F7;
    border-color: #E2E8EC; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: 0; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.autocomplete-suggestions {
  min-width: 160px;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  overflow: auto; }
  .autocomplete-suggestions .autocomplete-suggestion {
    display: block;
    padding: 10px 15px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    -epub-hyphens: auto;
    -ms-word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }

.autocomplete-suggestion:hover,
.autocomplete-selected,
.bootstrap-select.btn-group .dropdown-menu li.selected a {
  text-decoration: none;
  color: #fff !important;
  background-color: #009688; }

.help-block {
  color: #F44336;
  float: left;
  width: 100%;
  font-size: 12px; }

/* ==========================================================================
   Custom Drropzone
   ========================================================================== */
.dropzone {
  min-height: auto !important;
  border: transparent;
  border-radius: 2px !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1) !important;
  background: #F2F7F7 !important;
  margin: 0 !important;
  padding: 20px !important; }
  .dropzone .dz-default {
    margin: 0 !important;
    cursor: pointer;
    padding-top: 35px;
    position: relative; }
    .dropzone .dz-default:after {
      content: "\f093";
      position: absolute;
      left: calc(50% - 12.5px);
      top: 5px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 25px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

/* ==========================================================================
   Custom Buttons
   ========================================================================== */
.btn {
  padding: 7px 12px !important;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: none !important;
    box-shadow: none !important; }

.btn-sm {
  padding: 5px 10px !important; }

.btn-xs {
  width: 25px;
  height: 25px;
  padding: 0 !important; }
  .btn-xs i {
    line-height: 25px; }

/* ==========================================================================
   Color Placeholder
   ========================================================================== */
::-webkit-input-placeholder {
  color: #777;
  font-weight: 500 !important; }

:-moz-placeholder {
  color: #777;
  font-weight: 500 !important; }

::-moz-placeholder {
  color: #777;
  font-weight: 500 !important; }

:-ms-input-placeholder {
  color: #777;
  font-weight: 500 !important; }

/* Custom tooltip */
.tooltip-inner {
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 500;
  background-color: #262B33; }

.tooltip.right .tooltip-arrow {
  border-right-color: #262B33; }

.tooltip.left .tooltip-arrow {
  border-left-color: #262B33; }

.tooltip.top .tooltip-arrow {
  border-top-color: #262B33; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #262B33; }

/* Custons */
.nav-container .nav > li > a {
  white-space: normal; }

.input_icon {
  position: relative;
  padding-right: 50px; }
  .input_icon button {
    position: absolute;
    right: 0;
    top: calc(50% - 15px); }

.input_autocomplete input {
  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 18px 0;
  border-bottom: solid 1px #E1E1E1; }
  .input_autocomplete input:focus {
    border-bottom: solid 2px #009688;
    box-shadow: none; }

.form_add_cont md-select {
  margin: 0; }

.page_number {
  width: 70px;
  padding: 0 !important;
  margin: 0 !important; }
  .page_number > select {
    padding: 8px 15px 8px 10px !important; }

.nav-collapsed-min .balance_menu {
  display: none; }

.page {
  padding: 15px; }

.pagination {
  margin: 0; }

.title_page {
  margin-bottom: 5px;
  width: 100%;
  display: inline-block; }

.name_profile {
  line-height: 36px;
  text-transform: center; }

/*==========================================================================
	Custom Login
===========================================================================*/
.body-auth {
  background-image: url(../images/background/bg.png); }

.transparent_fillet {
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0 60px; }
  .transparent_fillet .has-error span.error {
    margin: 4px 0 0;
    color: #EC574B;
    font-size: 9px !important;
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.5px; }

.custom-wrapper {
  margin: 0; }

.main-body-custom {
  max-width: 480px;
  margin: 0 auto; }

.custom-body-inner {
  position: relative; }

.custom_input {
  border-radius: 0 !important;
  background-color: #e6e4e4;
  text-align: center;
  color: #333;
  height: 50px;
  width: 100%;
  border: 0; }
  .custom_input:focus {
    background-color: #fff; }

.btn_login,
.btn_login:hover,
.btn_login:focus {
  background-color: #4e9292 !important;
  border: solid 1px #4e9292 !important;
  border-radius: 0 !important;
  padding: 15px 0 !important;
  color: #fff;
  display: block;
  width: 100%; }

.label_input {
  display: block;
  width: 100%;
  margin: 0 0 15px; }
  .label_input > span {
    font-size: 12px;
    display: block;
    margin-bottom: 3px;
    font-weight: 500;
    color: #626571; }

.over_auto {
  overflow: auto; }

.over_hide {
  overflow: hidden; }

.info_help {
  position: relative;
  padding-right: 40px; }
  .info_help > button {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    background-color: #E5E5E5;
    border: none;
    color: #4C4F5F;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
    .info_help > button:hover, .info_help > button:focus {
      color: #fff;
      background-color: #3D4051; }

/* 	==========================================================================
	Custom Notify
	==========================================================================	*/
div[class*="notifyjs-metro-"] {
  display: table; }
  div[class*="notifyjs-metro-"] .image,
  div[class*="notifyjs-metro-"] .text-wrapper {
    position: relative;
    left: auto;
    top: auto;
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
    transform: none;
    -webkit-transform: none; }
  div[class*="notifyjs-metro-"] .text-wrapper {
    max-width: 290px;
    padding-left: 0; }

div[class*="notifyjs-metro-"],
.notifyjs-metro-base .text-wrapper {
  color: #fff;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  border-radius: 3px; }

.notifyjs-metro-base .image i {
  font-size: 28px; }

.notifyjs-metro-base .text {
  font-weight: 600; }

.notifyjs-metro-success {
  background-color: #009688 !important;
  border: 1px solid #009688 !important; }

.notifyjs-metro-warning {
  background-color: #FFCA28 !important;
  border: 1px solid #FFCA28 !important; }

.notifyjs-metro-info {
  background-color: #00BCD4 !important;
  border: 1px solid #00BCD4 !important; }

.notifyjs-metro-custom {
  background-color: #586980 !important;
  border: 1px solid #586980 !important; }

/* ==========================================================================
   Block Boxes
   ========================================================================== */
.box_disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  text-align: center; }
  .box_disabled i {
    font-size: 30px;
    color: #F79638; }
  .box_disabled span {
    display: block;
    margin-top: 10px;
    color: #3D4051;
    font-size: 15px;
    font-weight: 500; }

/* ==========================================================================
   Custom Modals
   ========================================================================== */
.modal-body {
  position: relative;
  padding: 0; }

.modal_content {
  border-bottom: solid 1px #E5E5E5;
  padding: 20px !important; }

.modal_alert {
  text-align: center; }
  .modal_alert md-dialog-actions {
    width: 100%;
    display: block;
    min-height: 1px;
    padding: 0 15px 15px; }
    .modal_alert md-dialog-actions button {
      color: #fff !important;
      margin: 0;
      padding: 6px 8px;
      font-size: 12px;
      min-height: 1px; }
      .modal_alert md-dialog-actions button:first-child, .modal_alert md-dialog-actions button:first-child:hover, .modal_alert md-dialog-actions button:first-child:focus {
        float: left !important;
        background-color: #F44336 !important;
        border-color: #F44336 !important; }
      .modal_alert md-dialog-actions button:last-child, .modal_alert md-dialog-actions button:last-child:hover, .modal_alert md-dialog-actions button:last-child:focus {
        float: right !important;
        background-color: #009688 !important;
        border-color: #009688 !important; }

/* ==========================================================================
   Custom Dropdowns
   ========================================================================== */
.panel_dropdown {
  position: relative; }
  .panel_dropdown .panel-heading {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    background: #F2F7F7; }

.dropdown-menu {
  padding: 0;
  border: none;
  margin: 0; }
  .dropdown-menu > li > a {
    padding: 10px 15px;
    white-space: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    -epub-hyphens: auto;
    -ms-word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 12px; }
    .dropdown-menu > li > a i {
      font-size: 18px;
      width: 18px;
      margin: 2px 5px 0 0; }
    .dropdown-menu > li > a:hover i,
    .dropdown-menu > li > a:hover span {
      color: #fff; }
  .dropdown-menu:after {
    display: none; }

/* ==========================================================================
   Custom Tables
   ========================================================================== */
.table_full {
  overflow: auto;
  position: relative; }

.table-dynamic .table-filters,
.table-dynamic .table-footer {
  margin: 0;
  z-index: 500; }

.table-filters {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 0 !important; }

.table > thead > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > th:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > th:first-child,
.table > tfoot > tr > td:first-child {
  border-left: none; }

.table > thead > tr > th:last-child,
.table > thead > tr > td:last-child,
.table > tbody > tr > th:last-child,
.table > tbody > tr > td:last-child,
.table > tfoot > tr > th:last-child,
.table > tfoot > tr > td:last-child {
  border-right: none; }

table.list {
  border: none;
  margin: 0 !important;
  border-top: solid 1px #ddd; }
  table.list thead tr th,
  table.list tbody tr td {
    vertical-align: middle !important;
    padding: 6px 8px;
    font-size: 13px; }
    table.list thead tr th .btn-icon,
    table.list tbody tr td .btn-icon {
      display: inline-table; }

.table_cash_flow thead tr th {
  background: #F2F7F7;
  text-align: center;
  text-transform: uppercase; }

.table_cash_flow thead tr th,
.table_cash_flow thead tr td,
.table_cash_flow tbody tr th,
.table_cash_flow tbody tr td {
  padding: 10px;
  font-size: 13px; }

.table-hover > tbody > tr {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.th_sort {
  position: relative;
  cursor: pointer;
  padding-right: 30px !important; }
  .th_sort:after {
    position: absolute;
    top: calc(50% - 4px);
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.2;
    content: "\e150"; }
  .th_sort.asc:after {
    opacity: 0.4;
    content: "\e155"; }
  .th_sort.desc:after {
    opacity: 0.4;
    content: "\e156"; }

.table_extract thead th {
  padding: 8px !important; }
  .table_extract thead th.th_sort {
    padding-right: 30px !important; }

.table_extract tbody td {
  cursor: pointer; }

table.table_ofx {
  min-width: 100%; }
  table.table_ofx thead,
  table.table_ofx tbody,
  table.table_ofx td,
  table.table_ofx th {
    display: block; }
  table.table_ofx tr {
    display: flex; }
    table.table_ofx tr:after {
      content: ' ';
      display: block;
      visibility: hidden;
      clear: both; }
  table.table_ofx thead th {
    height: 32px;
    text-align: center; }
  table.table_ofx thead tr th,
  table.table_ofx tbody tr td {
    float: left;
    flex: 1 1 auto;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    table.table_ofx thead tr th:nth-child(1),
    table.table_ofx tbody tr td:nth-child(1) {
      min-width: 40px;
      width: 2%;
      max-width: 40px; }
    table.table_ofx thead tr th:nth-child(2),
    table.table_ofx tbody tr td:nth-child(2) {
      min-width: 100px;
      width: 6%;
      max-width: 100px; }
    table.table_ofx thead tr th:nth-child(3), table.table_ofx thead tr th:nth-child(5), table.table_ofx thead tr th:nth-child(7),
    table.table_ofx tbody tr td:nth-child(3),
    table.table_ofx tbody tr td:nth-child(5),
    table.table_ofx tbody tr td:nth-child(7) {
      min-width: 180px;
      width: 15%; }
    table.table_ofx thead tr th:nth-child(4), table.table_ofx thead tr th:nth-child(6),
    table.table_ofx tbody tr td:nth-child(4),
    table.table_ofx tbody tr td:nth-child(6) {
      min-width: 180px;
      width: 20%; }
    table.table_ofx thead tr th:nth-child(8),
    table.table_ofx tbody tr td:nth-child(8) {
      min-width: 120px;
      width: 8%;
      max-width: 120px; }
  table.table_ofx tbody {
    max-height: calc(100vh - 120px);
    overflow-y: auto; }
    table.table_ofx tbody td {
      min-height: 79px; }
      table.table_ofx tbody td.null_item {
        min-width: 100% !important;
        min-height: 32px;
        text-align: center; }
      table.table_ofx tbody td > * {
        width: 100%; }
    table.table_ofx tbody * {
      font-size: 12px !important; }
  table.table_ofx .attention {
    display: block;
    margin: 0 0 10px;
    color: #ec564b;
    font-size: 16px !important; }

.box_ofx_info {
  background: #f2f7f7;
  padding-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 20px;
  margin-top: 20px; }
  .box_ofx_info > div {
    border-right: solid 1px #ddd; }
    .box_ofx_info > div:last-child {
      border: none; }

/* ==========================================================================
   Custom Autocomplete
   ========================================================================== */
.angucomplete-dropdown {
  width: 100%;
  z-index: 1000;
  margin-top: 30px;
  padding: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }

.angucomplete-searching {
  padding: 8px 15px; }

.angucomplete-holder input {
  height: 38px;
  display: block;
  margin-top: 0;
  background: 0 0;
  padding: 8px 12px !important;
  border-width: 0 0 1px;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left; }
  .angucomplete-holder input:focus {
    box-shadow: inset 0 -1px 0 #009688; }

.angucomplete-row {
  margin: 0;
  padding: 8px 15px;
  color: #3D4051; }

.angucomplete-selected-row {
  background-color: #009688;
  padding: 8px 15px;
  color: #fff; }

.dropdown-header {
  padding: 5px 10px;
  white-space: normal; }

/* ==========================================================================
   Plan account
   ========================================================================== */
.tree {
  list-style: none;
  padding-left: 10px; }
  .tree ol {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #333;
    position: relative; }
  .tree:before,
  .tree ol:before {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    top: -16px;
    bottom: 0;
    left: 0;
    border-left: 1px solid; }
  .tree li {
    position: relative;
    padding-left: 20px; }
    .tree li:before {
      content: "";
      display: block;
      width: 15px;
      height: 0;
      border-top: 1px solid;
      position: absolute;
      top: 24px;
      left: 0; }
    .tree li:last-child:before {
      background: white;
      height: auto;
      bottom: 0; }

.box_list_plan > .tree > li {
  padding-left: 12px !important; }
  .box_list_plan > .tree > li > button {
    left: 0 !important; }

.list_plan_cont {
  position: relative;
  height: 36px;
  line-height: 36px;
  color: #777;
  font-weight: 400;
  margin: 0;
  padding: 5px 70px 5px 0;
  cursor: default;
  border-radius: 0;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out; }
  .list_plan_cont > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block; }
  .list_plan_cont .text_plan {
    padding-left: 20px;
    color: #333;
    font-weight: 500;
    text-transform: uppercase; }
  .list_plan_cont > div {
    opacity: 0.2;
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 9px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .list_plan_cont > div .btn-xs {
      cursor: pointer; }
  .list_plan_cont:hover > div {
    opacity: 1.0; }

.btn_plus_plan {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 8px;
  top: 13px;
  background-color: #E5E5E5;
  z-index: 9;
  border: none;
  color: #4C4F5F;
  border-radius: 100%;
  font-size: 12px;
  line-height: 22px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .btn_plus_plan:hover {
    color: #fff;
    text-decoration: none;
    background-color: #475568;
    border-color: #3f4b5c; }

.or {
  display: inline-block;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 10px);
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #9C9DA6;
  line-height: 30px;
  color: #fff;
  border-radius: 100%;
  font-size: 11px; }
  .or:after, .or:before {
    content: "";
    display: block;
    position: absolute;
    background: #E5E5E5;
    width: 2px;
    height: 70px;
    left: calc(50% - 1px);
    z-index: 1; }
  .or:after {
    bottom: 100%;
    background: -webkit-linear-gradient(bottom, #E5E5E5, #fff);
    background: -o-linear-gradient(bottom, #E5E5E5, #fff);
    background: -moz-linear-gradient(bottom, #E5E5E5, #fff);
    background: linear-gradient(bottom, #E5E5E5, #fff); }
  .or:before {
    top: 100%;
    background: -webkit-linear-gradient(top, #E5E5E5, #fff);
    background: -o-linear-gradient(top, #E5E5E5, #fff);
    background: -moz-linear-gradient(top, #E5E5E5, #fff);
    background: linear-gradient(top, #E5E5E5, #fff); }

.btn_or {
  font-size: 50px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.col-sm-6:hover .btn_or {
  color: #F79638; }

/* ==========================================================================
   Dropdown Checkbox
   ========================================================================== */
.dropdown_check {
  display: block;
  top: calc(100% + 3px);
  left: 0;
  right: auto; }
  .dropdown_check:after {
    left: 15px;
    right: auto; }
  .dropdown_check .panel-heading .btn_help {
    float: right;
    font-size: 16px;
    margin: -2px -8px 0 0;
    line-height: 18px;
    width: 22px;
    height: 22px;
    padding: 0;
    text-align: center; }
  .dropdown_check li label {
    position: relative;
    display: block;
    padding: 10px 40px 10px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
    margin: 0;
    cursor: pointer !important;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
    .dropdown_check li label input[type="checkbox"] {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      margin: 0; }
    .dropdown_check li label span:after {
      content: "";
      display: none;
      position: absolute;
      right: 10px;
      top: calc(50% - 5px);
      width: 12px;
      height: 7px;
      border: solid 3px #3D4051;
      border-top: 0;
      border-right: 0;
      -webkit-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
      transform: rotateZ(-45deg);
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out; }
    .dropdown_check li label input[type="checkbox"]:checked + span:after {
      display: block;
      border-color: #3D4051; }
    .dropdown_check li label:hover {
      text-decoration: none;
      border-color: #fff;
      color: #fff;
      background-color: #009688; }
      .dropdown_check li label:hover span:after {
        border-color: #fff !important; }

.more_filter i {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .more_filter i.active {
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    transform: rotateZ(180deg); }

/* ==========================================================================
   Footer
   ========================================================================== */
.body-auth .footer_system {
  display: none; }

.footer_system a {
  position: relative;
  display: inline-block;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .footer_system a:after {
    display: inline-block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #9EA0A8;
    position: absolute;
    left: 0;
    bottom: -10px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .footer_system a:hover {
    color: #262B33;
    text-decoration: none !important; }
    .footer_system a:hover:after {
      opacity: 1;
      bottom: -1px; }

/* ==========================================================================
   Helpers
   ========================================================================== */
.relative {
  position: relative; }

.d_table {
  display: table; }

.d_table_middle {
  display: table-cell;
  vertical-align: middle; }

.d_table_cell {
  display: table-cell;
  vertical-align: middle; }

.c_white {
  color: #fff !important; }

.size_full {
  width: 100%;
  height: 100%; }

.w_full {
  width: 100%; }

.h_full_device {
  min-height: 100vh; }

.w_50 {
  width: 50px; }

.w_100 {
  width: 100px; }

.w_130 {
  width: 130px; }

.w_150 {
  width: 150px; }

.t_center {
  text-align: center !important; }

.t_right {
  text-align: right !important; }

.t_left {
  text-align: left !important; }

.text_normal {
  text-transform: none !important; }

.f_size_25 {
  font-size: 25px; }

.f_size_50 {
  font-size: 50px; }

.c_default {
  cursor: default !important; }

.bold {
  font-weight: bold; }

.m_0_auto {
  margin: 0 auto; }

.margin_0 {
  margin: 0 !important; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m_right_5 {
  margin-right: 5px; }

.m_top_10 {
  margin-top: 10px !important; }

.m_top_13 {
  margin-top: 13px; }

.m_top_15 {
  margin-top: 15px !important; }

.m_top_20 {
  margin-top: 20px; }

.m_top_28 {
  margin-top: 28px; }

.m_top_30 {
  margin-top: 30px; }

.m_bottom_0 {
  margin-bottom: 0px; }

.m_bottom_5 {
  margin-bottom: 5px; }

.m_bottom_15 {
  margin-bottom: 15px; }

.m_bottom_20 {
  margin-bottom: 20px; }

.m_bottom_30 {
  margin-bottom: 30px; }

.padding_0 {
  padding: 0 !important; }

.padding_20 {
  padding: 20px !important; }

.padding_10 {
  padding: 10px !important; }

.padding_30 {
  padding: 30px !important; }

.p_top_0 {
  padding-top: 0; }

.p_bottom_5 {
  padding-bottom: 5px !important; }

/* ==========================================================================
   Responsive
   ========================================================================== */
@media (max-width: 991px) {
  .m_md_top_0 {
    margin-top: 0px !important; }
  .m_md_top_10 {
    margin-top: 10px !important; } }

@media (max-width: 767px) {
  .app.nav-collapsed-min .top-header .logo .logo-text {
    display: inline-block; }
  .app.nav-collapsed-min .top-header .top-nav {
    padding: 64px 0 !important; }
  .app.nav-collapsed-min #nav-container {
    position: static;
    float: left;
    display: block; }
  .app.nav-collapsed-min .nav-container .nav > li > a {
    overflow: hidden; }
  .ul_name > li {
    display: inline-block !important;
    float: right !important; }
  .ul_name .open .dropdown-menu {
    position: absolute;
    right: 15px;
    top: calc(100% + 10px);
    left: auto;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .or {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin: 20px 0 40px; }
    .or:after, .or:before {
      width: 400%;
      height: 2px;
      top: calc(50% - 1px) !important;
      bottom: auto !important; }
    .or:after {
      right: 100% !important;
      left: auto !important;
      background: -webkit-linear-gradient(right, #E5E5E5, transparent);
      background: -o-linear-gradient(right, #E5E5E5, transparent);
      background: -moz-linear-gradient(right, #E5E5E5, transparent);
      background: linear-gradient(right, #E5E5E5, transparent); }
    .or:before {
      left: 100% !important;
      background: -webkit-linear-gradient(left, #E5E5E5, transparent);
      background: -o-linear-gradient(left, #E5E5E5, transparent);
      background: -moz-linear-gradient(left, #E5E5E5, transparent);
      background: linear-gradient(left, #E5E5E5, transparent); }
  .transparent_fillet {
    padding: 0 30px; }
  .m_sm_bottom_10 {
    margin-bottom: 10px; }
  .m_sm_bottom_15 {
    margin-bottom: 15px; } }

/* ==========================================================================
   BACK END
   ========================================================================== */
.md-select-menu-container.md-active {
  z-index: 1000000; }

table.fixedHeader-floating {
  z-index: 9999; }

.modal-open .modal {
  z-index: 1050; }

.dropzone .dz-preview {
  margin: 0;
  padding: 7px; }

.dropzone .dz-preview .dz-image {
  border-radius: 4px !important; }

/* ==========================================================================
   Custom Checkbox
   ========================================================================== */
.label_checkbox {
  display: flex;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  font-weight: normal;
  position: relative; }
  .label_checkbox input[type="checkbox"] {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer; }
    .label_checkbox input[type="checkbox"] + span {
      display: inline-block;
      width: 18px;
      height: 18px;
      position: relative; }
    .label_checkbox input[type="checkbox"] + span:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 1px;
      border: 1px solid #3D4051;
      -webkit-transition: all 0.1s ease-out;
      transition: all 0.1s ease-out; }
    .label_checkbox input[type="checkbox"] + span:after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 6px;
      display: block;
      width: 5px;
      height: 1px;
      border: 2px solid #fff;
      border-top-width: 0;
      border-left-width: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      opacity: 0; }
    .label_checkbox input[type="checkbox"]:checked + span:after {
      height: 10px;
      opacity: 1; }
    .label_checkbox input[type="checkbox"]:checked + span:before {
      background-color: #3D4051;
      border-color: #3D4051; }
    .label_checkbox input[type="checkbox"]:disabled + span:before,
    .label_checkbox input[type="checkbox"]:disabled:checked + span:before {
      border-color: #DCDCDC; }
    .label_checkbox input[type="checkbox"]:disabled:checked + span:before {
      background-color: #DCDCDC; }
    .label_checkbox input[type="checkbox"]:disabled + span:after,
    .label_checkbox input[type="checkbox"]:disabled + span:before,
    .label_checkbox input[type="checkbox"]:disabled:checked + span:after,
    .label_checkbox input[type="checkbox"]:disabled:checked + span:before {
      cursor: no-drop;
      box-shadow: none !important; }
  .label_checkbox:hover input[type="checkbox"] + span:before {
    box-shadow: inset 1px 1px 0px #3D4051, inset -1px -1px 0px #3D4051; }
  .label_checkbox.check_white {
    color: #fff; }
    .label_checkbox.check_white input[type="checkbox"] + span:before {
      border: 1px solid #fff; }
    .label_checkbox.check_white input[type="checkbox"]:before {
      background-color: #fff;
      border-color: #fff; }

/* ==========================================================================
   Cutom Radio
   ========================================================================== */
.label_radio {
  display: inline-block;
  margin: 0;
  cursor: pointer;
  font-weight: normal;
  position: relative; }
  .label_radio input[type="radio"] {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer; }
    .label_radio input[type="radio"] + span {
      display: inline-block;
      width: 18px;
      height: 18px;
      position: relative; }
    .label_radio input[type="radio"] + span:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 1px solid #3D4051;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out; }
  .label_radio:hover input[type="radio"] + span:before,
  .label_radio input[type="radio"]:checked + span:before {
    border: 2px solid #3D4051 !important; }
  .label_radio input[type="radio"] + span:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #3D4051;
    opacity: 0;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0); }
  .label_radio input[type="radio"]:checked + span:after {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1); }
  .label_radio input[type="radio"]:disabled + span:after,
  .label_radio input[type="radio"]:disabled + span:before {
    cursor: no-drop; }
  .label_radio input[type="radio"]:disabled + span:after {
    background-color: #DCDCDC; }
  .label_radio input[type="radio"]:disabled + span:before,
  .label_radio:hover input[type="radio"]:disabled + span:before {
    border: solid 1px #DCDCDC !important; }
  .label_radio input[type="radio"]:disabled:checked + span:before {
    border: solid 2px #DCDCDC !important; }

/* ==========================================================================
   Custom Switch
   ========================================================================== */
.label_switch {
  display: inline-block;
  margin: 0;
  cursor: pointer;
  font-weight: normal;
  position: relative; }
  .label_switch input[type="checkbox"] {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer; }
    .label_switch input[type="checkbox"] + span {
      display: inline-block;
      width: 38px;
      height: 18px;
      position: relative; }
      .label_switch input[type="checkbox"] + span:before {
        content: "";
        display: block;
        width: 38px;
        height: 22px;
        border-radius: 20px;
        border: 1px solid #dfdfdf;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out; }
  .label_switch:hover input[type="checkbox"] + span:before {
    box-shadow: inset 0 0 15px #eee; }
  .label_switch input[type="checkbox"] + span:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background: #fff;
    border-radius: 20px;
    border: solid 1px #dfdfdf;
    box-shadow: 2px 1px 1px -2px rgba(0, 0, 0, 0.4); }
  .label_switch input[type="checkbox"]:checked + span:after {
    left: 18px;
    border: solid 1px #5FBEAA;
    box-shadow: none; }
  .label_switch input[type="checkbox"]:checked + span:before,
  .label_switch input[type="checkbox"]:disabled:checked + span:before {
    border: 1px solid #5FBEAA;
    background-color: #5FBEAA; }
  .label_switch:hover input[type="checkbox"]:checked + span:before {
    box-shadow: none; }
  .label_switch input[type="checkbox"]:disabled + span {
    opacity: 0.6; }
    .label_switch input[type="checkbox"]:disabled + span:before {
      background-color: #f7f7f7; }
    .label_switch input[type="checkbox"]:disabled + span:after {
      background-color: #f3f3f3; }
    .label_switch input[type="checkbox"]:disabled + span:after {
      background-color: #fff; }
    .label_switch input[type="checkbox"]:disabled + span:before, .label_switch input[type="checkbox"]:disabled + span:after {
      cursor: no-drop; }

/* 	==========================================================================
	Loading Element
	==========================================================================	*/
.blockElement > div {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #606371;
  background: -moz-linear-gradient(left, #606371 10%, rgba(126, 228, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #606371 10%, rgba(126, 228, 255, 0) 42%);
  background: -o-linear-gradient(left, #606371 10%, rgba(126, 228, 255, 0) 42%);
  background: -ms-linear-gradient(left, #606371 10%, rgba(126, 228, 255, 0) 42%);
  background: linear-gradient(to right, #606371 10%, rgba(126, 228, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 0.3s infinite linear;
  animation: load3 0.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }
  .blockElement > div:before {
    width: 50%;
    height: 50%;
    background: #606371;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: ''; }
  .blockElement > div:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* 	==========================================================================
	Loading Button
	==========================================================================	*/
.btn.blocked {
  position: relative;
  color: transparent !important; }
  .btn.blocked:after {
    content: "" !important;
    position: absolute !important;
    left: calc(50% - 8px) !important;
    top: calc(50% - 8px) !important;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important;
    color: #fff !important;
    text-align: center !important;
    background: transparent !important;
    display: inline-block !important;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-animation: spin 0.7s infinite linear !important;
    -moz-animation: spin 0.7s infinite linear !important;
    -ms-animation: spin 0.7s infinite linear !important;
    animation: spin 0.7s infinite linear !important; }
  .btn.blocked.btn-default:after {
    color: #4c5667 !important; }
  .btn.blocked > div {
    display: none !important;
    opacity: 0 !important; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

@media (min-width: 1000px) {
  .page_entries {
    display: -webkit-flex;
    display: flex;
    width: 100%; }
    .page_entries > div:first-child {
      width: 100%;
      width: calc(100% - 300px); }
    .page_entries > div:last-child {
      min-width: 300px;
      max-width: 300px;
      padding-left: 15px; } }
