.page-dashboard {

    h3 {
        font-size: 22px;
        font-weight: normal;
        line-height: 1;
        margin: 0 0 30px;
    }

    .box-stats {
        text-align: center;
        padding-top: 20px; 
        padding-bottom: 20px;
        &.no-border {
            .box-num {
                border: 0;
            }
        }
        .box-desc {
            font-size: $font-size-small;
            line-height: 26px;
            color: $skin-gray-dark;
            text-transform: uppercase;
        }
        .box-num {
            font-size: 42px;
            line-height: 80px;
            font-weight: 300;
            @media screen and (min-width: $screen-sm-min) {
                border-right: 2px solid $skin-gray;
            }
        }
    }

    .metrics {
        margin-bottom: 55px;

        .metric {
            display: block;
            margin-bottom: 10px;
            font-size: 26px;
            color: $brand-primary;
        }

        .metric-info {
            text-transform: uppercase;
            font-size: 16px;
            color: #aaa;
        }
    }

    .progressbar-container {
        margin-bottom: 25px;
    }

    // 
    .panel-box {
        position: relative;
        border-radius: $border-radius-large;

        .panel-top,
        .panel-bottom {
            height: 100px;
            padding: 30px 15px;
            font-size: 40px;
            line-height: 1;
        }

        .panel-bottom {
            border-top: 1px solid #dfdfdf;
        }

        .panel-info {
            position: absolute;
            width: 100%;
            top: 50%;
            margin-top: -12px;
            text-align: center;

            span {
                height: 24px;
                display: inline-block;
                padding: 4px 10px;
                text-transform: uppercase;
                line-height: 14px;
                background-color: #fff;
                border: 1px solid #dfdfdf;
                font-size: 11px;
                color: $text-muted;
                border-radius: 1em;
            }
        }

        .bg-light {
            background-color: #f9f9f9;
        }
    }    

}
