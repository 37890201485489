
@mixin bg-style ($background, $color) {
    background-color: $background;
    color: $color;
    a {
        color: $color;
        &:hover {
            color: $color;
        }
    }
}

@mixin bg-variant-alt ($color) {
    background-color: $color;
    color: $reverse;
    &:hover {
        background-color: $color;
        color: $reverse;
    }
}

body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

// Overrides Angular-Material
body p,
html p {
    line-height: $line-height-base;
}

::selection {
    background: $brand-primary;
    color: $reverse;
}
::-moz-selection {
    background: $brand-primary;
    color: $reverse;
}

:focus {
    outline: none;
}

a {
    text-decoration: none;
    &:active,
    &:focus{
        text-decoration: none;
        outline: none;
    }
}

span.block {
    display: block;
}

.no-margin {
    margin: 0;
}
.no-margin-bottom {
    margin-bottom: 0;
}
.no-border {
    border: 0;
}
.no-border-radius {
    border-radius: 0;
}
.no-padding {
    padding: 0;
}
.no-shadow {
    box-shadow: 0;
}
.full-width {
    width: 100%;
}

.ui-section {
    margin-bottom: 60px;
}
.section-header {
    font-weight: normal;
    font-size: 34px;
    line-height: 40px;
    color: $brand-primary;
    margin: 30px 0;
}


// background
// -----------------
// overrides Bootstrap
a.bg-primary { @include bg-variant-alt ($brand-primary); }
a.bg-success { @include bg-variant-alt ($brand-success); }
a.bg-info { @include bg-variant-alt ($brand-info); }
a.bg-warning { @include bg-variant-alt ($brand-warning); }
a.bg-danger { @include bg-variant-alt ($brand-danger); }

// normal
.bg-white { @include bg-style(#fff, $text-color); }
.bg-body { @include bg-style($body-bg, $text-color); }
.bg-dark { @include bg-style($dark, $reverse); }
.bg-dark-light { @include bg-style($dark-light, $reverse); }
.bg-black { @include bg-style(#000, $reverse); }
.bg-primary { @include bg-style($brand-primary, $reverse); }
.bg-primary-light { @include bg-style($skin-light, $reverse); }
.bg-success { @include bg-style($brand-success, $reverse); }
.bg-info { @include bg-style($brand-info, $reverse); }
.bg-info-alt { @include bg-style($brand-info-alt, $reverse); }
.bg-warning { @include bg-style($brand-warning, $reverse); }

.bg-danger { @include bg-style($brand-danger, $reverse); }
.bg-dark { @include bg-style($dark, $reverse); }
.bg-bright { @include bg-style($bright, $text-color); }
.bg-white { @include bg-style($reverse, $text-color); }

.bg-facebook { @include bg-style(#335397, $reverse); }
.bg-twitter { @include bg-style(#00c7f7, $reverse); }
.bg-google-plus { @include bg-style(#df4a32, $reverse); }



