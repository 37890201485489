// overrides Bootstrap style
.progress {
    box-shadow: none;
    background-color: $progress-bg;
}

.progressbar-xs {
    height: 12px;
}

.progress-rounded {
    border-radius: 50px;
}
.progress-bar {
    box-shadow: none;
}

// custom
.progressbar-xs { height: 10px; }
.progressbar-sm { height: 15px; }


.progressbar-container {
    margin-bottom: 20px;
    .progress {
        margin-bottom: 5px;
    }
    .desc {
        color: $skin-gray-dark;
    }
}

.vprogressbar-container {
    height: 250px;
    margin-top: 25px;
    border-bottom: $skin-gray 2px solid;
    position: relative;
    &.brand-info { border-color: $brand-info; }
    &.brand-success { border-color: $brand-success; }
    .vprogressbar {
        padding-left: 10px;
        li {
            position: relative;
            height: 248px;
            width: 35px;
            background: $skin-gray;
            margin-right: 18px;
            float: left;
            list-style: none;
        }
    }
    .vprogressbar-percent {
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
    }
    .vprogressbar-legend {
        position: absolute;
        top: 0px;
        right: 0px;
        padding-left: 0;
        padding: 5px 10px;
        text-align: left;
        border-radius: $border-radius-base;
        background: rgba(255,255,255,.3);

        li {
            display:  block;
            font-size: 11px;
            margin-bottom: 5px;
            list-style:  none;
        }

        .vpointer {
            height: 10px;
            width: 10px;
            display: inline-block;
            position: relative;
            top: 1px;
            margin-right: 5px;
        }
    }

    .vprogressbar-info {
        color:#ccc;
    }
}
